import React from 'react' 
import { TextField } from '@mui/material';
 
 const SearchBar = ({setSearchQuery}: {setSearchQuery: (searchVal: string) => (searchValue: string) => void}) => (
    <form style={{
        marginLeft: 5
      }}>
      <TextField
        id="search-bar"
        className="text"
        onChange={(e) => {
          setSearchQuery(e.target.value);
        }}
        label="Search"
        variant="outlined"
        placeholder="Search..."
        size="small"
      />
    </form>
  );

  export default SearchBar