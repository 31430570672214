import { Middleware } from "@reduxjs/toolkit"

const round = (number: number) => Math.round(number * 100) / 100

function hasTypeProperty(value: unknown): unknown {
    return typeof value === 'object' && value !== null && 'type' in value;
  }

const logger: Middleware = store => next => (action) => {
    console.group((hasTypeProperty(action)) ? (action as {type: string}).type : 'Unknown action')
    console.info('dispatching', action)
    const start = performance.now()
    let result = next(action)
    const end = performance.now()
    const diff = round(end - start)
    console.log('reducer process time:', diff)
    console.log('next state', store.getState())
    console.groupEnd()
    return result
}

export default logger