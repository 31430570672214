import React, { useEffect } from 'react'
import axios, { AxiosResponse } from 'axios'

import Spinner from './Spinner'

const HGViewer = () => {

    const [url, setUrl] = React.useState(null)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_BASE}/v1/ThirdParty`, {headers: { "Content-Type": 'application/json' }})
            .catch((error) => {
                if (error) {
                    console.log(error)
                }
            }).then(response => {
                //const url = response.data.url
                setUrl((response as AxiosResponse).data.url)
        })
    })

    if (!url) return <Spinner />

    return (
        <div style={{height: '100%', width: '100%'}} >
            <iframe style={{marginTop: '10px', height: 'calc(100% - 30px)'}} src={url} width={'100%'} />
        </div>
    )
}

export  default HGViewer