import HomeButtons from './HomeButtons';
import HomeSearch from './HomeSearch';


const Home = () => {

    return (
        <div>
            <h1>Home</h1>
            <HomeSearch />
            <HomeButtons />
        </div>
    )
}

export default Home