import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppDispatch, RootState } from '../../configureStore'

export const slice = createSlice({
    name: 'requests',
    initialState: {
        requests: [],
        loading: true,
        error: null,
        pageSize: 25,
        page: 0,
        requestCount: null,
        maxPage: null,
        previouslyLoaded: null,
        sort: [{field:"createdAt", sort:"desc"}]
    },
    reducers: {
        loadRequests : (state, action) => { 
            state.requests = action.payload.requests
            // state.page = action.payload.page - 1 
            // state.pageSize = action.payload.PAGE_SIZE 
            // state.requestCount = action.payload.totalRequests
            // state.maxPage = action.payload.maxPage
            state.loading= false
            // state.previouslyLoaded = true 
        },
        setLoading: state => { state.loading = true },
        setError: (state, action) => { state.error = action.payload},
        clearError: state => { state.error = null },
        setPage: (state, action) => {
            state.page = action.payload
            state.loading= false
        },
        setPageSize: (state, action) => {
            state.pageSize = action.payload
            state.loading= false
        },
        setSort: (state, action) => {
            state.sort = action.payload
            state.loading= false
        },
    }
})

export const { setLoading, loadRequests, setError, clearError, setPage, setPageSize, setSort } = slice.actions

export const loadRequestsAsync = (filter: {columnField: string, operatorValue: string, value:string}[], sort: {field: string, sort: string}[]) => async (dispatch: AppDispatch) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/requests`,{params: {
            // page: page,
            // pageSize: pageSize,
            filter: filter,
            sort: sort
          }})
        console.log(res.data)
        dispatch(loadRequests(res.data))
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

export const setPageSizeAsync = (newPageSize: number) => async (dispatch: AppDispatch) => {
    dispatch(setLoading())
    try {
        dispatch(setPageSize(newPageSize))
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

export const setPageAsync = (newPage: number) => async (dispatch: AppDispatch) => {
    dispatch(setLoading())
    try {
        dispatch(setPage(newPage))
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

export const RequestsState = (state: RootState) => state.requests

export default slice.reducer