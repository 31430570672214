import { useSelector } from 'react-redux'

// mui
import { Typography, Grid } from '@mui/material'

import {AuthState} from "../auth/authSlice"

// utils
import { capitalizeFirstLetter, highlightText} from "../../utils/formatting"
import EditPerson from "./EditPerson";
import SetArchiveStatus from '../archived/SetArchiveStatus'
import ReturnFromArchive from '../archived/ReturnFromArchive';
import UnenrollPerson from './UnenrollPerson';
import DeletePerson from './DeletePerson'
import { Person } from '../../models/person'
import { DataSource } from '../../models/dataSource';

const ChartHeader = ({person}: {person: Person}) => {
    const { user } = useSelector(AuthState)

    const { firstName, middleName, lastName, gender, birthDate, isActive, isCwEnrolled, suffix  } = person

    var date = new Date('1940-01-01').toISOString();
    var highlight = date.valueOf() >= birthDate.valueOf()

    return (
        <Typography variant='h5' component='h5' style={{marginTop: 10}}>
            <Grid container spacing={2}>
            {lastName}{suffix && ` ${suffix}`}, {firstName}{middleName && ` ${middleName}` } | {capitalizeFirstLetter(gender)} | {(highlight && highlightText(birthDate,'#fafa02')) || birthDate }
            <EditPerson dataFromParent={person} /> 
            {isActive  && <SetArchiveStatus person={person} />}
            {!isActive  && <ReturnFromArchive person={person}/>}
            {user?.organization.defaultDatasourceId === DataSource.CW && isCwEnrolled && <UnenrollPerson person={person} />}
            {user?.organization.defaultDatasourceId === DataSource.CW &&  <DeletePerson person={person} />}
            </Grid>
        </Typography>
    )
}
export default ChartHeader

