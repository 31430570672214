import React, {useState} from 'react'
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props: AlertProps, ref: React.ForwardedRef<HTMLDivElement>) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ProgressNoteAlert = ({message}: {message: string}) =>  {
    
        const [open, setOpen] = useState(true); 
        //Leave this true since we are not using a button
    
        const handleClose: (event: React.SyntheticEvent<any> | Event, reason?: SnackbarCloseReason) => void = (event, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            setOpen(false);
          };

    return (
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">{message}</Alert>
      </Snackbar>
    )
}

export default ProgressNoteAlert
