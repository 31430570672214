import { useEffect, Fragment } from 'react'
import { useAppDispatch, useAppSelector } from "../../hooks"
import { useParams } from 'react-router-dom'

// context
import { FhirResourceState, getProceduresAsync} from './fhirResourceSlice'
import { PeopleState, getPersonAsync, setError } from "../people/peopleSlice"


//import local component
import ChartHeader from '../people/ChartHeader'
import ProceduresDG from './ProceduresDG'

const Procedures = () => {
    const { id } = useParams()

    const { procedures } = useAppSelector(FhirResourceState)
    const { person } = useAppSelector(PeopleState)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (!id) {
            dispatch(setError('Route Path Parameter For Person ID Missing'))
        } else {  
            dispatch(getPersonAsync(id))
            dispatch(getProceduresAsync(id))
        }
    }, [dispatch, id])

        return (
            <Fragment>
                {person &&  <div style={{marginLeft: 10, marginTop: "30px"}}>
                                <ChartHeader person={person} />
                                <h1>Procedures</h1>
                                <ProceduresDG procedures={procedures}/>
                            </div>}
            </Fragment>
        )

}

export default Procedures