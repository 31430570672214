import { GridFilterItem, GridSortItem } from '@mui/x-data-grid'
import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppDispatch, RootState } from '../../configureStore'


export const slice = createSlice({
    name: 'archive',
    initialState: {
        patients: [],
        loading: true,
        batch: null,
        error: null,
        pageSize: 25,
        page: 0,
        peopleCount: 0,
        maxPage: null,
        previouslyLoaded: false,
        sort: [{field:"lastName", sort:"asc"}] as GridSortItem[],
    },
    reducers: {
        loadPeople: (state, action) => {
            const { people, page, PAGE_SIZE, totalPeople, maxPage } = action.payload
            state.patients = people
            if (page) state.page = action.payload.page - 1
            if (PAGE_SIZE) state.pageSize = action.payload.PAGE_SIZE
            if (totalPeople) state.peopleCount = action.payload.totalPeople
            if (maxPage) state.maxPage = action.payload.maxPage
            state.loading= false
            state.previouslyLoaded = true
        },
        setError: (state, action) => { state.error = action.payload },
        setLoading: state => { state.loading = true },
        clearError: state => { state.error = null },
        setPage: (state, action) => {
            state.page = action.payload
            state.loading= false
        },
        setPageSize: (state, action) => {
            state.pageSize = action.payload
            state.loading= false
        },
        setSort: (state, action) => {
            state.sort = action.payload
            state.loading= false
        },
    }
})

export const { loadPeople, setError, setLoading, clearError, setPage, setPageSize, setSort  } = slice.actions

export const getArchivedPatientsAsync = (page: number, pageSize: number, filter: GridFilterItem[] | undefined, sort?: GridSortItem[]) => async (dispatch: AppDispatch) => {
    dispatch(setLoading())
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/archive`,{params: {
            page: page,
            pageSize: pageSize,
            filter: filter,
            sort: sort
          }})
        dispatch(loadPeople(res.data))
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

export const updateArchiveStatusAsync = (person: {id: string, isActive: boolean, reason: string | null}) => async (dispatch: AppDispatch) => {
    dispatch(setLoading())
    const { isActive, id, reason} = person
    try {
        if (!person) return
        console.log(reason)
        await axios.put(`${process.env.REACT_APP_API_BASE}/v1/archive/${id}`,{isActive,archiveReason:reason })
        window.location.reload()
    } catch (err) {
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

export const setPageSizeAsync = (newPageSize: number) => async (dispatch: AppDispatch) => {
    dispatch(setLoading())
    try {
        dispatch(setPageSize(newPageSize))
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

export const setPageAsync = (newPage: number) => async (dispatch: AppDispatch) => {
    dispatch(setLoading())
    try {
        dispatch(setPage(newPage))
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

export const ArchiveState = (state: RootState) => state.archive
export default  slice.reducer