import { Fragment, useState } from 'react'
import { useAppDispatch, useAppSelector } from "../../hooks"

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Grid,
    Tooltip,
    Button,
    IconButton
} from "@mui/material"

import RemoveCircleOutlineSharpIcon from '@mui/icons-material/RemoveCircleOutlineSharp';

// context
import { clearError, PeopleState, deleteProgressNoteAsync } from "./peopleSlice"

import ShowAlert from '../../widgets/ShowAlert'
import { ClientFile } from '../../models/clientFile';

const DeleteProgressNote = ({doc}: {doc: ClientFile}) => {

    const { error } = useAppSelector(PeopleState)
    const dispatch = useAppDispatch()

    const initialState = {
        open: false
    }

    const [dialog, setDialog] = useState(initialState)
    const { open} = dialog

    const handleClickOpen = () => { 
        dispatch(clearError())
        setDialog({...dialog, open: true}) }
    const handleClose = () => {
        dispatch(clearError())
        setDialog(initialState)
    }
    const handleSubmit = () => {
        dispatch(clearError())
        dispatch(deleteProgressNoteAsync(doc.id))
        setDialog(initialState)
    }

    return (
        <Fragment>
            <IconButton color='inherit' onClick={handleClickOpen}>
                <Tooltip title='Delete Progress Note'>
                    <RemoveCircleOutlineSharpIcon fontSize='small' style={{color: '#939393'}}/>
                </Tooltip>
            </IconButton>                   
    
            <Dialog open={open} id="deleteProgressNoteModal" onClose={handleClose} aria-labelledby={'form-dialog-title'} fullWidth={true} maxWidth={'md'}>
                <DialogTitle id='form-dialog-title'>Delete Progress Note</DialogTitle>
                <DialogContent>
                <Grid item xs={12} >&nbsp;</Grid>
                    <DialogContentText>
                        { error && <ShowAlert severity={'error'}>{error}</ShowAlert> }
                    </DialogContentText>

                    <DialogContentText>You are about to delete a Progress Note </DialogContentText>
                    <DialogContentText>Do you with to continue?</DialogContentText>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color={'primary'}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color={'primary'}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}
export default DeleteProgressNote