
import { Fragment } from 'react'
import { styled } from '@mui/material/styles';
import { Typography,ButtonBase, Grid } from '@mui/material'
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux'

import { AuthState} from '../features/auth/authSlice'
import { DataSource } from '../models/dataSource';


const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  height: 200,
  [theme.breakpoints.down('sm')]: {
    width: '100% !important', // Overrides inline-style
    height: 100,
  },
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: .85,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
    '& .MuiTypography-root': {
      border: '4px solid currentColor',
    },
  },
}));

const Image = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: '#000032',
  transition: theme.transitions.create('opacity'),
}));

const ImageMarked = styled('span')(({ theme }) => ({
  height: 3,
  width: 18,
  backgroundColor: 'white',
  position: 'absolute',
  bottom: -2,
  left: 'calc(50% - 9px)',
  transition: theme.transitions.create('opacity'),
}));


export default function HomeButtons() {

    const { user } = useSelector(AuthState)

  return ( <Fragment>
          <Grid container spacing={1}>
              <Grid item xs={12} sm={6} lg={3}>
                  <ImageButton
                      focusRipple
                      style={{
                          width: '100%',
                      }}
                  >
                      <ImageBackdrop className="MuiImageBackdrop-root" />
                      <Link to="/people" style={{color: 'white'}}>
                          <Image>
                              <Typography
                                  component="span"
                                  variant="subtitle1"
                                  color="white"
                                  sx={{
                                      position: 'relative',
                                      p: 4,
                                      pt: 2,
                                      pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                                  }}
                              >
                                  Patient Management
                                  <ImageMarked className="MuiImageMarked-root" />
                              </Typography>
                          </Image>
                      </Link>
                  </ImageButton>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                  <ImageButton
                      focusRipple
                      style={{
                          width: '100%',
                      }}
                  >
                      <ImageBackdrop className="MuiImageBackdrop-root" />
                      <Link to="/batches" style={{color: 'white'}}>
                          <Image>
                              <Typography
                                  component="span"
                                  variant="subtitle1"
                                  color="white"
                                  sx={{
                                      position: 'relative',
                                      p: 4,
                                      pt: 2,
                                      pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                                  }}
                              >
                                  Upload CSV File
                                  <ImageMarked className="MuiImageMarked-root" />
                              </Typography>
                          </Image>
                      </Link>
                  </ImageButton>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                  <ImageButton
                      focusRipple
                      style={{
                          width: '100%',
                      }}
                  >
                      <ImageBackdrop className="MuiImageBackdrop-root" />
                      <Link to="/commonwellLink" style={{color: 'white'}}>
                          <Image>
                              <Typography
                                  component="span"
                                  variant="subtitle1"
                                  color="white"
                                  sx={{
                                      position: 'relative',
                                      p: 4,
                                      pt: 2,
                                      pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                                  }}
                              >
                                  Patient Linking
                                  <ImageMarked className="MuiImageMarked-root" />
                              </Typography>
                          </Image>
                      </Link>
                  </ImageButton>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                  <ImageButton
                      focusRipple
                      style={{
                          width: '100%',
                      }}
                  >
                      <ImageBackdrop className="MuiImageBackdrop-root" />
                      <Link to="/requests" style={{color: 'white'}}>
                          <Image>
                              <Typography
                                  component="span"
                                  variant="subtitle1"
                                  color="white"
                                  sx={{
                                      position: 'relative',
                                      p: 4,
                                      pt: 2,
                                      pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                                  }}
                              >
                                  Requests
                                  <ImageMarked className="MuiImageMarked-root" />
                              </Typography>
                          </Image>
                      </Link>
                  </ImageButton>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                  <ImageButton
                      focusRipple
                      style={{
                          width: '100%',
                      }}
                  >
                      <ImageBackdrop className="MuiImageBackdrop-root" />
                      <Link to="/reciprocalData" style={{color: 'white'}}>
                          <Image>
                              <Typography
                                  component="span"
                                  variant="subtitle1"
                                  color="white"
                                  sx={{
                                      position: 'relative',
                                      p: 4,
                                      pt: 2,
                                      pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                                  }}
                              >
                                  Reciprocal Data
                                  <ImageMarked className="MuiImageMarked-root" />
                              </Typography>
                          </Image>
                      </Link>
                  </ImageButton>
              </Grid>

              <Grid item xs={12} sm={6} lg={3}>
                  <ImageButton
                      focusRipple
                      style={{
                          width: '100%',
                      }}
                  >
                      <ImageBackdrop className="MuiImageBackdrop-root" />
                      <Link to="/archive" style={{color: 'white'}}>
                          <Image>
                              <Typography
                                  component="span"
                                  variant="subtitle1"
                                  color="white"
                                  sx={{
                                      position: 'relative',
                                      p: 4,
                                      pt: 2,
                                      pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                                  }}
                              >
                                  Patient Archive
                                  <ImageMarked className="MuiImageMarked-root" />
                              </Typography>
                          </Image>
                      </Link>
                  </ImageButton>
              </Grid>

            {user?.organization.defaultDatasourceId === DataSource.CW &&
              <Grid item xs={12} sm={6} lg={3}>
                  <ImageButton
                      focusRipple
                      style={{
                          width: '100%',
                      }}
                  >
                      <ImageBackdrop className="MuiImageBackdrop-root" />
                      <Link to="/merge" style={{color: 'white'}}>
                          <Image>
                              <Typography
                                  component="span"
                                  variant="subtitle1"
                                  color="white"
                                  sx={{
                                      position: 'relative',
                                      p: 4,
                                      pt: 2,
                                      pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                                  }}
                              >
                                  Merge Patients
                                  <ImageMarked className="MuiImageMarked-root" />
                              </Typography>
                          </Image>
                      </Link>
                  </ImageButton>
              </Grid>
                }

          </Grid>
    </Fragment>
  );
}