import { ChangeEvent, Fragment, MouseEventHandler, useState } from 'react'
import { useAppDispatch, useAppSelector } from "../../hooks"

import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Grid,
    TextField,
    Tooltip,
    Button, 
    Chip,
    SelectChangeEvent
} from "@mui/material"
import Alert from '@mui/material/Alert';

// context
import { ArchiveState, updateArchiveStatusAsync, clearError} from './archiveSlice'

import PaperComponent from '../../widgets/PaperComponent';
import { Person } from '../../models/person';

const SetArchiveStatus = ({person}: {person: Person}) => {

    const { error } = useAppSelector(ArchiveState)
    const dispatch = useAppDispatch()

    const {id, firstName, lastName, isActive} = person

    const initialState = {
        open: false,
        reason: null
    }

    const [dialog, setDialog] = useState(initialState)
    const { open, reason } = dialog

    const handleClickOpen: MouseEventHandler<HTMLDivElement> = () => { 
        dispatch(clearError())
        setDialog({...dialog, open: true}) }
    const handleClose: MouseEventHandler<HTMLButtonElement> = () => {
        dispatch(clearError())
        setDialog(initialState)
    }
    const handleSubmit = () => {
        dispatch(updateArchiveStatusAsync({id, isActive:false,reason}))
        setDialog(initialState)
    }
    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => setDialog({...dialog, [e.target.name]: e.target.value})

    return isActive ? (
        <Fragment>
            <Box display='flex' justifyContent='flex-mid' >
                <Tooltip title='Archive Patient' >
                    <Chip
                        variant="filled"
                        sx={{ marginTop: 1 }}
                        color="primary"
                        size='small'
                        label="Archive"
                        onClick={handleClickOpen}
                    />
                </Tooltip>
            </Box>

            <Dialog open={open} id="setArchiveStatusModal" onClose={handleClose} aria-labelledby={'form-dialog-title'} fullWidth={true} maxWidth={'md'} PaperComponent={PaperComponent}>
                <DialogTitle id='form-dialog-title'>Set to Archived Status</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        { error && <Alert severity={'error'}>{error}</Alert> }
                    </DialogContentText>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                        <DialogContentText>You are about to Archive {firstName} {lastName} </DialogContentText>
                        <DialogContentText>Do you wish to continue?</DialogContentText>
                        </Grid> 
                        <Grid item xs={12}>
                            <TextField
                                margin='dense'
                                type='text'
                                id='reason'
                                name='reason'
                                label='Archive Reason'
                                value={reason}
                                onChange={onChange}
                                variant='outlined'
                            />
                        </Grid>   
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color={'primary'}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color={'primary'}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    ) : null
}

export default SetArchiveStatus