import axios from "axios";
import React, { ChangeEvent, Fragment, MouseEventHandler, useState } from 'react';
import { useAppDispatch, useAppSelector } from "../../hooks"
import moment from 'moment';

// MUI
import {
    Button, Card,
    CardContent,
    FormControl,
    Grid,
    IconButton,
    SelectChangeEvent,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import { DataGrid, GridToolbar, getGridStringOperators, GridColDef } from '@mui/x-data-grid';
import DownloadForOfflineSharpIcon from '@mui/icons-material/DownloadForOfflineSharp';

// context
import { BatchState, sendNewBatchZipJobAsync, cancelBatchZipJobAsync } from './batchSlice'

const DownloadBatchCompositesForm = () => {
    const dispatch = useAppDispatch()
    const { batchZips, batchZipJobs } = useAppSelector(BatchState)

    const [state, setState] = useState({
        downloadBatchId: '',
        numPatientsPerZip: '100'
    })

    const { downloadBatchId, numPatientsPerZip } = state
    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const onStartJobClick: MouseEventHandler<HTMLButtonElement> = async e => {
        e.preventDefault()
        try {
            await dispatch(sendNewBatchZipJobAsync(downloadBatchId, numPatientsPerZip))
        } catch (ex) {
            console.log(ex)
        }
    }

    const onCancelJobClick = React.useMemo(() => async (id: string) => {
        try {
            await dispatch(cancelBatchZipJobAsync(id))
        } catch (ex) {
            console.log(ex)
        }
    }, [dispatch])

    const handleDownloadClick = async (id: string) => {
        const res = await axios.get(
            `${process.env.REACT_APP_API_BASE}/v1/Batch/${id}`, {
            })

        const { signedUrl, fileName } = res.data

        const aTag = document.createElement('a')
        aTag.href = signedUrl
        aTag.target = '_blank'
        aTag.setAttribute('download', fileName)
        document.body.appendChild(aTag)
        aTag.click()
        aTag.remove()
    }

    const compareDottedStrings = (a: string, b: string) => {
        const splitA = a.split('.').map(part => isNaN(Number(part)) ? part : Number(part));
        const splitB = b.split('.').map(part => isNaN(Number(part)) ? part : Number(part));
    
        for (let i = 0; i < Math.max(splitA.length, splitB.length); i++) {
            const valA = splitA[i] !== undefined ? splitA[i] : -Infinity;
            const valB = splitB[i] !== undefined ? splitB[i] : -Infinity;
    
            if (valA < valB) return -1;
            if (valA > valB) return 1;
        }
        return 0;
    };

    const batchZipsColumnsFormat: GridColDef[] = React.useMemo( () => ([
        {field: 'download', headerName: '', width: 50, sortable: false, align: 'center', filterable: false,
            renderCell: (params) => {
                return <IconButton aria-label="download" onClick={() => {handleDownloadClick(params.row.id)}}>
                            <Tooltip title="Download">
                                <DownloadForOfflineSharpIcon />
                            </Tooltip>
                        </IconButton>
            }},
        {field: 'batchId', headerName: 'Batch ID', width: 200,
            sortComparator: compareDottedStrings,
            renderCell: (params) => {
                return params.row.batchId
            }},
        {field: 'fileName', headerName: 'File Name', width: 300,
            renderCell: (params) => {
                return params.row.fileName
            }},
        {field: 'createdAt', headerName: 'Date Added', width: 150,
            renderCell: (params) => {
                return moment(params.row.createdAt).isValid() ? moment(params.row.createdAt).format('YYYY-MM-DD') : ''
            }},
        ]),
        []
    );

    const batchZipJobsColumnsFormat: GridColDef[] = React.useMemo( () => ([
        {field: 'batchId', headerName: 'Batch ID', width: 200,
            sortComparator: compareDottedStrings,
            renderCell: (params) => {
                return params.row.batchId
            }},
        {field: 'size', headerName: 'Size', width: 150,
            renderCell: (params) => {
                return params.row.size
            }},
        {field: 'offset', headerName: 'Offset', width: 150,
            renderCell: (params) => {
                return params.row.offset
            }},
        {field: 'status', headerName: 'Status', width: 150,
            renderCell: (params) => {
                return params.row.jobIsStuck ?
                <span>'Job Is Stuck'
                    <IconButton aria-label="cancel job" onClick={() => {onCancelJobClick(params.row.id)}}>
                        <Tooltip title="Cancel Job">
                            <CancelIcon />
                        </Tooltip>
                    </IconButton>
                </span>
                : 'Generating Zip'
            }},
        ]),
        [onCancelJobClick]
    );

    const batchZipColumns = React.useMemo( () =>
        batchZipsColumnsFormat.map((col) => {
            return {
                ...col,
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'contains' || operator.value === 'equals',
                ),
            };
        }),
        [batchZipsColumnsFormat],
    );

    const batchZipJobsColumns = React.useMemo( () =>
        batchZipJobsColumnsFormat.map((col) => {
            return {
                ...col,
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'contains' || operator.value === 'equals',
                ),
            };
        }),
        [batchZipJobsColumnsFormat],
    );

    return <Fragment>
        <Card variant={'outlined'}>
            <CardContent>
                <Typography sx={{ fontSize: 14 }} style={{marginBottom: '15px', textTransform: 'uppercase'}}  >
                    Download Batch Composites
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={5}>
                        <FormControl fullWidth>
                            <TextField
                                type='text'
                                id='downloadBatchId'
                                name='downloadBatchId'
                                label='Batch Id'
                                value={downloadBatchId}
                                onChange={onChange}
                                variant='outlined'
                                fullWidth
                                required
                                size={'small'}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            size={'small'}
                            type='text'
                            id='numPatientsPerZip'
                            name='numPatientsPerZip'
                            label='Patients per Zip File'
                            value={numPatientsPerZip}
                            onChange={onChange}
                            variant='outlined'
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button onClick={onStartJobClick} variant={'contained'} color={'primary'} disabled={!downloadBatchId.trim().length}>Create Zip</Button>
                    </Grid>
                </Grid>
                {batchZipJobs.length > 0 &&
                    <div style={{ marginTop: 20, height: 650, width: '100%' }}>
                        <DataGrid
                            components={{
                                Toolbar: GridToolbar
                            }}
                            componentsProps={{
                                toolbar: {
                                    csvOptions: { disableToolbarButton: true },
                                    printOptions: { disableToolbarButton: true },
                                },
                            }}
                            style={{
                                backgroundColor: 'white'
                            }}
                            rows={batchZipJobs}
                            rowCount={batchZipJobs.length}
                            columns={batchZipJobsColumns}
                            rowsPerPageOptions={[25, 50, 100]}
                            initialState={{
                                sorting: {
                                    sortModel: [{field:"batchId", sort:"asc"}],
                                },
                                pagination: { pageSize: 25 },
                            }}
                        />
                    </div>
                }
                {batchZips.length > 0 &&
                    <div style={{ marginTop: 20, height: 650, width: '100%' }}>
                        <DataGrid
                            components={{
                                Toolbar: GridToolbar
                            }}
                            componentsProps={{
                                toolbar: {
                                    csvOptions: { disableToolbarButton: true },
                                    printOptions: { disableToolbarButton: true },
                                },
                            }}
                            style={{
                                backgroundColor: 'white'
                            }}
                            rows={batchZips}
                            rowCount={batchZips.length}
                            columns={batchZipColumns}
                            rowsPerPageOptions={[25, 50, 100]}
                            initialState={{
                                sorting: {
                                    sortModel: [{field:"batchId", sort:"asc"}],
                                },
                                pagination: { pageSize: 25 },
                            }}
                        />
                    </div>
                }
            </CardContent>
        </Card>
    </Fragment>
}

export default DownloadBatchCompositesForm
