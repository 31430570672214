import { Fragment, useState } from 'react'
import { useAppDispatch, useAppSelector } from "../../hooks"

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Grid,
    Tooltip,
    Button,
    IconButton
} from "@mui/material"

import RemoveCircleOutlineSharpIcon from '@mui/icons-material/RemoveCircleOutlineSharp';

// context
import { clearError, PeopleState, deleteContactPointAsync } from "./peopleSlice"

import ShowAlert from '../../widgets/ShowAlert'
import PaperComponent from '../../widgets/PaperComponent';
import { ContactPoint } from '../../models/contactPoint';


const DeleteContactPoint = ({contact}: {contact: ContactPoint}) => {

    const { error } = useAppSelector(PeopleState)
    const dispatch = useAppDispatch()

    const initialState = {
        open: false
    }

    const [dialog, setDialog] = useState(initialState)
    const { open} = dialog

    const handleClickOpen = () => { 
        dispatch(clearError())
        setDialog({...dialog, open: true}) }
    const handleClose = () => {
        dispatch(clearError())
        setDialog(initialState)
    }
    const handleSubmit = () => {
        dispatch(clearError())
        dispatch(deleteContactPointAsync(contact.id))
        setDialog(initialState)
    }

    return (
        <Fragment>
            <IconButton color='inherit' onClick={handleClickOpen}>
                <Tooltip title='Delete Contact Point'>
                    <RemoveCircleOutlineSharpIcon fontSize='small' style={{color: '#939393'}}/>
                </Tooltip>
            </IconButton>                   
    
            <Dialog open={open} id="deleteContactPointModal" onClose={handleClose} aria-labelledby={'form-dialog-title'} fullWidth={true} maxWidth={'md'} PaperComponent={PaperComponent}>
                <DialogTitle id='form-dialog-title'>Delete Contact Point</DialogTitle>
                <DialogContent>
                <Grid item xs={12} >&nbsp;</Grid>
                    <DialogContentText>
                        { error && <ShowAlert severity={'error'}>{error}</ShowAlert> }
                    </DialogContentText>

                    <DialogContentText>You are about to delete the contact point: {contact.value} </DialogContentText>
                    <DialogContentText>Do you with to continue?</DialogContentText>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color={'primary'}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color={'primary'}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}
export default DeleteContactPoint