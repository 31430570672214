import { GridSelectionModel } from '@mui/x-data-grid'
import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppDispatch, RootState } from '../../configureStore'
import { AdditionalName } from '../../models/additionalName'
import { Address } from '../../models/address'
import { BatchId } from '../../models/batchId'
import { ContactPoint } from '../../models/contactPoint'
import { CWLink } from '../../models/cwLink'
import { EndatoQuery } from '../../models/endatoQuery'
import { People } from '../../models/people'
import { PeopleBasic } from '../../models/peopleBasic'
import { Person } from '../../models/person'
import { Rate } from '../../models/rate'
import { SubmitType } from '../../models/submitType'
import { removeSubstring } from '../../utils/stringHelper'

export const slice = createSlice({
    name: 'people',
    initialState: {
        loading: true,
        people: [] as People[],
        peopleBasic: [] as PeopleBasic[],
        person: null as Person | null,
        error: null,
        pageSize: 25,
        // page: 0,
        // peopleCount: null,
        // maxPage: null,
        previouslyLoaded: false,
        sort: [{field:"lastName", sort:"asc"}],
        cwLinks: [] as CWLink[],
        success: null,
        viewFile: null,
        FileId: null,
        merged: false,
        noFacility: null,
        reciprocalPatients: null
    },
    reducers: {
        loadPeople: (state, action) => {
            const { people, page, PAGE_SIZE, totalPeople, maxPage } = action.payload

            state.people = people
            // if (page) state.page = action.payload.page - 1
            // if (PAGE_SIZE) state.pageSize = action.payload.PAGE_SIZE
            // if (totalPeople) state.peopleCount = action.payload.totalPeople
            // if (maxPage) state.maxPage = action.payload.maxPage
            state.loading= false
            state.previouslyLoaded = true
        },
        loadPeopleBasic: (state, action) => {
            state.peopleBasic = action.payload
            state.loading = false
        },
        loadReciprocal:(state, action) => {
            state.reciprocalPatients = action.payload
            state.loading= false
        },
        loadPerson: (state, action) => {
            state.person = action.payload
            state.loading= false
        },
        setCWLinks: (state,action) => {
            state.cwLinks = action.payload
        },
        setLoading: state => { state.loading = true },
        setError: (state, action) => { state.error = action.payload
                                        state.loading = false},
        setSuccess: (state, action) => { state.success = action.payload},
        setMerged: (state, action) => { state.merged = action.payload},
        setNoFacility: (state, action) => { state.noFacility =  action.payload},
        clearError: state => { state.error = null },
        /*setPage: (state, action) => {
            state.page = action.payload
            state.loading= false
        },*/
        setPageSize: (state, action) => {
            state.pageSize = action.payload
            state.loading= false
        },
        setSort: (state, action) => {
            state.sort = action.payload
            state.loading= false
        },
        setViewFile: (state, action) => {
            state.viewFile = action.payload.file
            state.FileId = action.payload.id
            state.loading= false
        }
    }
})


export const { loadPeople, loadPeopleBasic, loadReciprocal, loadPerson, setViewFile,setNoFacility, setLoading,setMerged, setError, setSuccess,clearError, setPageSize, /*setPage,*/ setSort, setCWLinks } = slice.actions


export const getPeopleAsync = () => async (dispatch: AppDispatch) => {
    dispatch(setLoading())
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/patient`)//,{params: {
        //     page: page,
        //     pageSize: pageSize,
        //     filter: filter,
        //     sort: sort
        //   }})
        dispatch(loadPeople(res.data))
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

export const getPeopleBasicAsync = () => async (dispatch: AppDispatch) => {
    dispatch(setLoading())
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/patient/$basic`, {})
        dispatch(loadPeopleBasic(res.data ))
        // console.log(res.data)
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

export const getReciprocalPatientsAsync = () => async (dispatch: AppDispatch) => {
    dispatch(setLoading())
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/patient/$reciprocal`, {})
        dispatch(loadReciprocal(res.data ))
        // console.log(res.data)
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

export const deleteProgressNoteAsync = (docId: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoading())
    try {
        await axios.delete(`${process.env.REACT_APP_API_BASE}/v1/progressNote/${docId}`)
        window.location.reload()
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

export const mergePatientsAsync = (keep: string | null, destroy: string | null) => async (dispatch: AppDispatch) => {
    dispatch(setLoading())
    try {
        await axios.post(`${process.env.REACT_APP_API_BASE}/v1/patient/$merge`, {keep,destroy})
        dispatch(getPeopleAsync())
        dispatch(setMerged(true))
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

export const deletePatientAsync = (patientId: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoading())
    try {
        await axios.delete(`${process.env.REACT_APP_API_BASE}/v1/patient/${patientId}`);
        (window as Window).location = `/people`;
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

export const unenrollPatientAsync = (patientId: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoading())
    try {
        await axios.post(`${process.env.REACT_APP_API_BASE}/v1/patient/${patientId}/$unenroll`)
        dispatch(getPersonAsync(patientId))
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

export const setPageSizeAsync = (newPageSize: number) => async (dispatch: AppDispatch) => {
    dispatch(setLoading())
    try {
        dispatch(setPageSize(newPageSize))
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

/*export const setPageAsync = (newPage: number) => async (dispatch: AppDispatch) => {
    dispatch(setLoading())
    try {
        dispatch(setPage(newPage))
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}*/

export const addNewPersonAsync = (person: Partial<Person & ContactPoint & Rate> & {addresses: Partial<Address>[]} & {contactPoints: Partial<ContactPoint>[]}) => async (dispatch: AppDispatch) => {
    dispatch(setLoading())
    const {firstName, middleName, suffix, lastName,  birthDate, gender,addresses, contactPoints, rate} = person
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_BASE}/v1/patient`,{firstName, middleName, suffix, lastName,  birthDate, gender,addresses, contactPoints, rate});
        (window as Window).location = `/people/${res.data.id}`;
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

export const addNewAddressAsync = (person: Person, addresses: Partial<Address>[], submitType: SubmitType) => async (dispatch: AppDispatch) => {
    try {
        await axios.post(`${process.env.REACT_APP_API_BASE}/v1/address`,{patientId: person.id,addresses})
        if(submitType === SubmitType.Submit) dispatch(getPersonAsync(person.id))
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

export const searchEndatoAddressAsync = (person: Person, endatoSearchPayload: EndatoQuery) => async (dispatch: AppDispatch) => {
    try {
        await axios.post(`${process.env.REACT_APP_API_BASE}/v1/endato`,{patientId: person.id,endatoSearchPayload})
        dispatch(getPersonAsync(person.id))
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

export const updateAddressAsync = (address: Partial<Address>) => async (dispatch: AppDispatch) => {
    dispatch(setLoading())

    const {type, use, line1, line2, city, state, postalCode, id} = address

    try {
        await axios.put(`${process.env.REACT_APP_API_BASE}/v1/address/${id}`,{type, use, line1, line2, city, state, postalCode})
        window.location.reload()
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

export const addNewAdditionalNameAsync = (person: Person, additionalName: Partial<AdditionalName>, submitType: SubmitType) => async (dispatch: AppDispatch) => {
    const {firstName,lastName, middleName, suffix} = additionalName

    try {
        await axios.post(`${process.env.REACT_APP_API_BASE}/v1/additionalName`,{patientId: person.id,additionalName:[{firstName,lastName, middleName, suffix}]})
        if(submitType === SubmitType.Submit) dispatch(getPersonAsync(person.id))
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

export const updateAdditionalNameAsync = (name: Partial<AdditionalName>) => async (dispatch: AppDispatch) => {
    dispatch(setLoading())

    const { id, firstName, middleName,lastName,suffix } = name

    try {
        if (!name) return
        await axios.put(`${process.env.REACT_APP_API_BASE}/v1/additionalName/${id}`,{id,firstName, middleName,lastName,suffix })
        window.location.reload()
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

export const setErrorAsync = (message: string) => async (dispatch: AppDispatch) => {
    dispatch(setError(message))
}

export const getPersonAsync = (id: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoading())
    try {
        if (!id) return
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/patient/${id}`)
        dispatch(loadPerson(res.data))
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

export const updatePersonAsync = (person: Partial<Person & BatchId>) => async (dispatch: AppDispatch) => {
    dispatch(setLoading())

    const { firstName, lastName, middleName, gender, birthDate, id, suffix, batchId } = person

    try {
        if (!person) return
        await axios.put(`${process.env.REACT_APP_API_BASE}/v1/patient/${ id}`,{ firstName, lastName, middleName, gender, birthDate, id, suffix, batchId })
        window.location.reload()
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

export const addNewContactAsync = (person: Person, contact: Partial<ContactPoint>, submitType: SubmitType) => async (dispatch: AppDispatch) => {
    const {use,value, system} = contact

    try {
        await axios.post(`${process.env.REACT_APP_API_BASE}/v1/ContactPoint`,{patientId: person.id,contactPoints:[{use,value,system}]})
        if(submitType === SubmitType.Submit) dispatch(getPersonAsync(person.id))
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            const errorMessage = err.response?.data.message 
            ? removeSubstring(err.response.data.message, 'Validation error:') 
            : err.response?.data.error 

            dispatch(setError(errorMessage))
        }
    }
}

export const requestRateUpdateAsync = (request: {id: string, rate: string, reason: string}) => async (dispatch: AppDispatch) => {
    dispatch(setLoading())

    const { id, rate,reason } = request

    try {
        if (!request) return
        await axios.put(`${process.env.REACT_APP_API_BASE}/v1/PatientQuery/${id}`,{id,rate, reason })
        window.location.reload()
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

export const updateContactPointAsync = (contact: Partial<ContactPoint>) => async (dispatch: AppDispatch) => {
    dispatch(setLoading())

    const { id, system, use, value } = contact

    try {
        if (!contact) return
        await axios.put(`${process.env.REACT_APP_API_BASE}/v1/ContactPoint/${id}`,{id,system, use, value })
        window.location.reload()
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

export const searchCWPatient = (id: string) => async (dispatch: AppDispatch) => {
    try {
        if (!id) return
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/patient/${id}/$searchEnrollment`)
        if(res.status === 201){
            dispatch(getPersonAsync(id))
        }
        else
            dispatch(setCWLinks(res.data))
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

export const linkCWPatient = (id: string, link: GridSelectionModel) => async (dispatch: AppDispatch) => {
    dispatch(setLoading())
    try {
        if (!id) return
        await axios.post(`${process.env.REACT_APP_API_BASE}/v1/patient/${id}/$enroll/${link}`)
        dispatch(getPersonAsync(id))
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

export const deleteAdditionalNameAsync = (id: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoading())
    try {
        if (!id) return
        await axios.delete(`${process.env.REACT_APP_API_BASE}/v1/additionalName/${id}`)
        window.location.reload()
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}


export const deleteContactPointAsync = (id: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoading())
    try {
        if (!id) return
        await axios.delete(`${process.env.REACT_APP_API_BASE}/v1/contactPoint/${id}`)
        window.location.reload()
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

export const deleteAddressAsync = (id: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoading())
    try {
        if (!id) return
        await axios.delete(`${process.env.REACT_APP_API_BASE}/v1/address/${id}`)
        window.location.reload()
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

export const setPersonIsDownloading = (person: Person) => async (dispatch: AppDispatch) => {
    const data = {...person, isDownloading: true};
    dispatch(loadPerson(data))
}

export const PeopleState = (state: RootState) => state.people

export default slice.reducer