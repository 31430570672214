import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from "../../hooks"

// mui
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Card, CardContent,Typography} from "@mui/material";
import {ReferralsState, loadReferralAsync} from './referralsSlice'
import { ReferralPerson } from '../../models/referralPerson';

const ReferralTable = () => {
    const { referral, selectedReferral } = useAppSelector(ReferralsState)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if(selectedReferral){
            dispatch(loadReferralAsync(selectedReferral))
        }
    }, [dispatch, selectedReferral])

    
    const headers = [
        {
            property: 'firstName', label: 'First Name', getValue: function (obj: ReferralPerson) {
                return obj.firstName
            }
        },
        {
            property: 'lastName', label: 'Last Name', getValue: function (obj: ReferralPerson) {
                return obj.lastName
            }
        },
        {
            property: 'birthDate', label: 'Birth Date', getValue: function (obj: ReferralPerson) {
                return obj.birthDate
            }
        },
        {
            property: 'gender', label: 'Gender', getValue: function (obj: ReferralPerson) {
                return obj.gender
            }
        },
    ]

    if(referral){
        return <Card variant={'outlined'}>
        <CardContent>
        <Typography sx={{ fontSize: 14 }} style={{marginBottom: '15px', textTransform: 'uppercase'}}  >
                    Patients to be Added
                </Typography>
        <TableContainer component={Paper} style={{marginTop: '30px'}}>

        <Table sx={{minWidth: 650}} size="small">

            <TableHead>
                <TableRow>
                    {headers.map(h => <TableCell key={h.property}>{h.label}</TableCell>)}
                </TableRow>
            </TableHead>
            <TableBody>
                {referral.Patients.map((b: ReferralPerson) => {
                    return <TableRow key={b.id} id={b.id}>
                        {headers.map(h => {
                            return <TableCell key={`${b.id}${h.property}`}>{h.getValue(b)}</TableCell>
                        })}
                    </TableRow>
                })
                }
            </TableBody>

        </Table>
    </TableContainer>
    </CardContent>
    </Card>
    }
    else return <Card variant={'outlined'}>
                    <CardContent>
                        <Typography sx={{ fontSize: 14 }} style={{marginBottom: '15px', textTransform: 'uppercase'}}  >
                            Please select a referral to view patients
                        </Typography>
                    </CardContent>
                </Card>
}

export default ReferralTable