import { ChangeEvent, Fragment, MouseEventHandler, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Grid,
    TextField,
    Tooltip,
    Button,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    SelectChangeEvent
} from "@mui/material"

import Alert from '@mui/material/Alert';
import EditSharpIcon from '@mui/icons-material/EditSharp';

// context
import { updateContactPointAsync, clearError, PeopleState } from "./peopleSlice"

import PaperComponent from '../../widgets/PaperComponent';
import { ContactPoint } from '../../models/contactPoint';

const EditContactPoint = (props: {dataFromParent: ContactPoint}) => {

    const { error } = useAppSelector(PeopleState)
    const dispatch = useAppDispatch()

    const initialState = {
        open: false,
        system: props.dataFromParent.system,
        use: props.dataFromParent.use,
        value: props.dataFromParent.value,

    }

    const [dialog, setDialog] = useState(initialState)
    const { open, system, use, value } = dialog

    const handleClickOpen: MouseEventHandler<HTMLButtonElement> = () => { 
        dispatch(clearError())
        setDialog({...dialog, open: true}) }
    const handleClose: MouseEventHandler<HTMLButtonElement> = () => {
        dispatch(clearError())
        setDialog(initialState)
    }
    const handleSubmit: MouseEventHandler<HTMLButtonElement> = () => {
        dispatch(clearError())
        dispatch(updateContactPointAsync({id:props.dataFromParent.id,system, use, value}))
        setDialog(initialState)
    }

    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => setDialog({...dialog, [e.target.name]: e.target.value})

    const selectUses = [{key:'home',label:'Home', value:'home'},{key:'mobile',label:'Mobile', value:'mobile'},{key: 'work',label:'Work', value:'work'},{key:'temp',label:'Temp', value:'temp'}]
    const selectTypes = [{key:'phone',label:'Phone', value:'phone'},{key: 'email',label:'Email', value:'email'}, {key:'sms',label:'SMS',value:'sms'},{key:'fax',label:'Fax',value:'fax'},{key:'url',label:'URL',value:'url'}]

    return (
        <Fragment>
            <IconButton color='inherit' onClick={handleClickOpen}>
                <Tooltip title='Edit Contact Point'>
                    <EditSharpIcon fontSize='small' style={{color: '#939393'}}/>
                </Tooltip>
            </IconButton>                   
    
            <Dialog open={open} id="editContactPointModal" onClose={handleClose} aria-labelledby={'form-dialog-title'} fullWidth={true} maxWidth={'md'} PaperComponent={PaperComponent}> 
                <DialogTitle id='form-dialog-title'>Update Contact Point</DialogTitle>
                <DialogContent>
                <Grid item xs={12} >&nbsp;</Grid>
                    <DialogContentText>
                        { error && <Alert severity={'error'}>{error}</Alert> }
                    </DialogContentText>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                        <FormControl variant="outlined" style={{minWidth: 200}} required>
                <InputLabel htmlFor="outlined-age-native-simple">Contact Type</InputLabel>
                <Select
                    native
                    required
                    value={system}
                    onChange={onChange}//{(val) => handleChange(val)}
                    label="Contact Type"
                    inputProps={{
                        name: 'system',
                        id: 'system',
                    }}
                    >
                    <option aria-label="None" value="" />
                    {selectTypes.map(item => {
                        return (<option key={item.key} value={item.value}>{item.label}</option>);
                        })}
                </Select>
            </FormControl>     
            </Grid>
            <Grid item xs={12}>
            <FormControl variant="outlined" style={{minWidth: 200}} required>
                <InputLabel htmlFor="outlined-age-native-simple">Contact Use</InputLabel>
                <Select
                    native
                    required
                    value={use}
                    onChange={onChange}//{(val) => handleChange(val)}
                    label="Contact Use"
                    inputProps={{
                        name: 'use',
                        id: 'use',
                    }}
                    >
                    <option aria-label="None" value="" />
                    {selectUses.map(item => {
                        return (<option key={item.key} value={item.value}>{item.label}</option>);
                        })}
                </Select>
            </FormControl>     
            </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin='dense'
                                type='text'
                                id='value'
                                name='value'
                                label='Contact Value'
                                value={value}
                                onChange={onChange}
                                variant='outlined'
                                fullWidth
                            />
                        </Grid>
                    
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color={'primary'}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color={'primary'}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}
export default EditContactPoint