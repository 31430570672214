import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
import { AppDispatch, RootState } from '../../configureStore';
import { AllergyIntolerance } from '../../models/fhirResources/allergyIntolerance';

export const slice = createSlice({
    name: 'fhirResource',
    initialState: {
        loading: true,
        allergyIntolerances: null as AllergyIntolerance[] | null,
        conditions: null,
        encounters: null,
        familyHistory: null,
        medications: null,
        medicalDevices: null,
        procedures: null,
        carePlans: null,
        error: null,
    },
    reducers: {
        setLoading: state => { state.loading = true },
        setError: (state, action) => {
            state.error = action.payload
            state.loading = false
        },
        loadAllergyIntolerances: (state, action) => {
            state.allergyIntolerances = action.payload
            state.loading = false
        },
        loadCarePlans: (state, action) => {
            state.carePlans = action.payload
            state.loading = false
        },
        loadConditions: (state, action) => {
            state.conditions = action.payload
            state.loading = false
        },
        loadEncounters: (state, action) => {
            state.encounters = action.payload
            state.loading = false
        },
        loadFamilyHistory: (state, action) => {
            state.familyHistory = action.payload
            state.loading = false
        },
        loadMedications: (state, action) => {
            state.medications = action.payload
            state.loading = false
        },
        loadMedicalDevices: (state, action) => {
            state.medicalDevices = action.payload
            state.loading = false
        },
        loadProcedures: (state, action) => {
            state.procedures = action.payload
            state.loading = false
        },
        clearError: state => { state.error = null },
    }
})

export const { setLoading, setError, clearError, loadCarePlans, loadConditions, loadEncounters, loadFamilyHistory, loadMedications, loadMedicalDevices, loadProcedures, loadAllergyIntolerances } = slice.actions

export const  getAllergyIntolerancesAsync = (patientId: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoading())
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/FhirResources/${patientId}/$allergy`)
        dispatch(loadAllergyIntolerances(res.data))
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

export const  getCarePlansAsync = (patientId: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoading())
    console.log('In slice careplans')
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/FhirResources/${patientId}/$careplans`)
        dispatch(loadCarePlans(res.data))
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

export const  getConditionsAsync = (patientId: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoading())
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/FhirResources/${patientId}/$conditions`)
        dispatch(loadConditions(res.data))
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

export const  getEncountersAsync = (patientId: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoading())
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/FhirResources/${patientId}/$encounters`)
        dispatch(loadEncounters(res.data))
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

export const  getFamilyHistoryAsync = (patientId: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoading())
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/FhirResources/${patientId}/$familyHistory`)
        dispatch(loadFamilyHistory(res.data))
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

export const  getMedicationsAsync = (patientId: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoading())
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/FhirResources/${patientId}/$medications`)
        dispatch(loadMedications(res.data))
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

export const  getMedicalDevicesAsync = (patientId: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoading())
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/FhirResources/${patientId}/$medicalDevices`)
        dispatch(loadMedicalDevices(res.data))
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

export const  getProceduresAsync = (patientId: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoading())
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/FhirResources/${patientId}/$procedures`)
        dispatch(loadProcedures(res.data))
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

export const FhirResourceState = (state: RootState) => state.fhirResource

export default slice.reducer