import React, { Fragment, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import moment from 'moment'

// state
import { PeopleState, getPeopleAsync, setPageSizeAsync, /*setPageAsync,*/ setSort } from './peopleSlice'

import Spinner from '../../layouts/Spinner'
import AddPerson from './AddPerson'

// MUI
import { DataGrid, GridColDef, GridToolbar, getGridStringOperators } from '@mui/x-data-grid';
import { Box, Link, Tooltip } from "@mui/material"
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';
import PersonSharpIcon from '@mui/icons-material/PersonSharp';
import HomeSearch from '../../layouts/HomeSearch'

const PeopleDG = () => {
    const dispatch = useAppDispatch()
    //Destructure of People State elements
    const { people, previouslyLoaded, pageSize  } = useAppSelector(PeopleState)
    // let filter

    useEffect(() => {
        dispatch(getPeopleAsync())
    }, [dispatch])


    //Defines People Data Grid columns
    const columnsFormat: GridColDef[] = React.useMemo( () => ([
        {field: 'viewDetails', headerName: '', width: 50, sortable: false, align: 'center', filterable: false,
            renderCell: (params) => {
                return <Link href={"/people/"+ params.row.id}><Tooltip title='View Details'><PersonSharpIcon /></Tooltip></Link>
            }},
        {field: 'firstName', headerName: 'First Name', width: 150,
            renderCell: (params) => {
                return params.row.firstName
            }},
        {field: 'lastName', headerName: 'Last Name', width: 150,
            renderCell: (params) => {
                return params.row.lastName
            }},
        {field: 'createdAt', headerName: 'Date Added', width: 150,
            renderCell: (params) => {
                return moment(params.row.createdAt).isValid() ? moment(params.row.createdAt).format('YYYY-MM-DD') : ''
            }},
        { field: 'birthDate', headerName: 'Birth Date', width: 150,
            renderCell: (params) => {
                return params.row.birthDate
            }},
        {field: 'cwLink', headerName: 'CW Linked?', width: 100, filterable: false,
            renderCell: (params) => {
                return params.row.cwLink ? <CheckCircleSharpIcon style={{color: 'green'}} />: <HighlightOffSharpIcon style={{color: 'red'}} />
            }},
        {field: 'returnData', headerName: 'Reciprocal Data', width: 125, filterable: false,sortable: false,
            renderCell: (params) => {
                return params.row.returnData ? <CheckCircleSharpIcon style={{color: 'green'}} />: <HighlightOffSharpIcon style={{color: 'red'}} />
            }},
            {field: 'address1', headerName: 'Address Line 1', width: 250, filterable: false,sortable: false,
            renderCell: (params) => {
                return params.row.line1
            }
                // valueGetter: (params) => {
                //     let result = [];
                //     if (params.row.Addresses) {
                //         if (params.row.Addresses[0].line1) {
                //         result.push(params.row.Addresses[0].line1);
                //         }
                //     } else {
                //         result = [""];
                //     }
                //     return result.join(", ");
                // }
            },
            {field: 'address2', headerName: 'Address Line 2', width: 200, filterable: false,sortable: false,
            renderCell: (params) => {
                return params.row.line2
            }
                // valueGetter: (params) => {
                //     let result = [];
                //     if (params.row.Addresses) {
                //         if (params.row.Addresses[0].line2) {
                //         result.push(params.row.Addresses[0].line2);
                //         }
                //     } else {
                //         result = [""];
                //     }
                //     return result.join(", ");
                // }
            },
            { field: 'city', headerName: 'City', width: 200, filterable: false,sortable: false,
            renderCell: (params) => {
                return params.row.city
            }
                // valueGetter: (params) => {
                //     let result = [];
                //     if (params.row.Addresses) {
                //         if (params.row.Addresses[0].city) {
                //         result.push(params.row.Addresses[0].city);
                //         }
                //     } else {
                //         result = [""];
                //     }
                //     return result.join(", ");
                // }
            },
            {field: 'state', headerName: 'State', width: 150, filterable: false,sortable: false,
            renderCell: (params) => {
                return params.row.state
            }
                // valueGetter: (params) => {
                //     let result = [];
                //     if (params.row.Addresses) {
                //         if (params.row.Addresses[0].state) {
                //         result.push(params.row.Addresses[0].state);
                //         }
                //     } else {
                //         result = [""];
                //     }
                //     return result.join(", ");
                // }
            },
            {field: 'postalCode', headerName: 'Zip Code', width: 150, filterable: false,sortable: false,
            renderCell: (params) => {
                return params.row.postalCode
            }
                // valueGetter: (params) => {
                //     let result = [];
                //     if (params.row.Addresses) {
                //         if (params.row.Addresses[0].postalCode) {
                //         result.push(params.row.Addresses[0].postalCode);
                //         }
                //     } else {
                //         result = [""];
                //     }
                //     return result.join(", ");
                // }
            },
            { field: 'email', headerName: 'Email', width: 300, filterable: false,sortable: false,
            renderCell: (params) => {
                return params.row.email
            }
            //     valueGetter: (params) => {
            //         let result = [];
            //         if (params.row.ContactPoints) {
            //             params.row.ContactPoints.forEach((point) => {
            //             if (point.system === 'email') {
            //                 result.push(point.value);
            //             }
            //         })
            //         } else {
            //             result = [""];
            //         }
            //         return result.join(", "); 
            // }
        },
            { field: 'phone', headerName: 'Phone', width: 150, filterable: false,sortable: false,
            renderCell: (params) => {
                return params.row.phone
            }
            //     valueGetter: (params) => {
            //         let result = [];
            //         if (params.row.ContactPoints) {
            //             params.row.ContactPoints.forEach((point) => {
            //             if (point.system === 'phone') {
            //                 result.push(point.value);
            //             }
            //         })
            //         } else {
            //             result = [""];
            //         }
            //         return result.join(", "); 
            // }
        }, 
    ]),
    [])

    const columns = React.useMemo( () =>
          columnsFormat.map((col) => {
            return {
              ...col,
              filterOperators: getGridStringOperators().filter(
                (operator) => operator.value === 'contains' || operator.value === 'equals',
              ),
            };
          }),
        [columnsFormat],
      );

    if(people.length > 0 || previouslyLoaded) {
        return (<Fragment>
            <div>
                <Box display='flex' justifyContent='flex-end'>
                <AddPerson/>
                </Box>
                <HomeSearch />
                <div style={{ height: 650, width: '100%' }}>
                    <DataGrid
                        components={{
                            Toolbar: GridToolbar
                        }}
                        componentsProps={{
                            toolbar: {
                              csvOptions: { disableToolbarButton: true },
                              printOptions: { disableToolbarButton: true },
                            },
                        }}
                        style={{
                            backgroundColor: 'white'
                        }}
                        rows={people}
                        rowCount={people.length}
                        columns={columns}
                        rowsPerPageOptions={[25, 50, 100]}
                        initialState={{
                            sorting: {
                              sortModel: [{field:"lastName", sort:"asc"}],
                            },
                            pagination: { pageSize: 100 },
                          }}
                        
                    />
                </div>
            </div>
            </Fragment>
        )
        }
    // If state people array is not populated this returns
        else
            return <Spinner />
}


export default PeopleDG