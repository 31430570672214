import React, { Fragment, useEffect, useState } from 'react'
import moment from 'moment'

import RenderExpandableCell from '../../utils/RenderExpandableCell';
import Spinner from '../../layouts/Spinner'
import CustomToolbar from '../../widgets/CustomToolbar'

// MUI
import { DataGrid, getGridStringOperators, GridColDef } from '@mui/x-data-grid';
import { Encounter } from '../../models/fhirResources/encounter';
import { EncounterView } from '../../models/fhirResources/encounterView';
import { ResourceType } from '../../models/fhirResources/resourceType';

const prepTableData =  (data: Encounter[]) => {
    return data.map(c => {
        const id = c.id
        const description = Object.keys(c.description).length > 0 ? c.description.display : ''
        const startDate = c.period ? Object.keys(c.period).length > 0 ? moment(c.period.start).format('YYYY-MM-DD') : moment(c.period.end).format('YYYY-MM-DD') : ''
        const performer = c.primaryPerformer?.name ? c.primaryPerformer.name[0].text : ''
        const location = c.location ? c.location.name : ''
        const indication = c.json.contained?.filter(i => i.resourceType === ResourceType.Condition).map(i=> i.code?.text) || ''

        return {id,description,startDate,performer, location, indication}
    })
}


const EncountersDG = ({encounters}: {encounters: Encounter[] | null}) => {
    const [tableData, setTableData] = useState([] as EncounterView[]);

    useEffect(() => {
        setTableData(prepTableData(encounters || []))
    }, [encounters])

    const requestSearch = (searchValue: string) => {
        const searchRegex = new RegExp(`.*${searchValue}.*`, "ig");
        const filteredRows = tableData.filter((o) => {
          return (Object.keys(o) as (keyof EncounterView)[]).some((k) => {
            return searchRegex.test(o[k]?.toString() ?? '');
          });
        });
        setTableData(filteredRows);
      };

    //Defines conditions Data Grid columns
    const columnsFormat: GridColDef[] = React.useMemo( () => ([
        {field: 'description', headerName: 'Description', width: 500,
            renderCell: (params) => {
                return <RenderExpandableCell value={params.row.description}/>
            }},
        {field: 'startDate', headerName: 'Start Date', width: 150,
            renderCell: (params) => {
                return params.row.startDate
            }},
        {field: 'performer', headerName: 'Performer', width: 250, 
            renderCell: (params) => {
                return params.row.performer 
            }},
        {field: 'location', headerName: 'Location', width: 500, 
            renderCell: (params) => {
                return params.row.location 
            }},
        {field: 'indication', headerName: 'Indication', width: 500, 
            renderCell: (params) => {
                return <RenderExpandableCell value={params.row.indication}/> 
            }}
    ]),
    [])

    const columns = React.useMemo( () =>
          columnsFormat.map((col) => {
            return {
              ...col,
              filterOperators: getGridStringOperators().filter(
                (operator) => operator.value === 'contains' || operator.value === 'equals',
              ),
            };
          }),
        [columnsFormat],
      );



    if(tableData) {
        
        return (<Fragment>
            <div>
                <div style={{ height: 650, width: '100%' }}>
                    <DataGrid
                        components={{
                            Toolbar: CustomToolbar
                        }}
                        componentsProps={{
                            toolbar: {
                                setSearchQuery: (searchVal: string) => requestSearch(searchVal),
                            },
                        }}
                        style={{
                            backgroundColor: 'white'
                        }}
                        rows={tableData}
                        columns={columns}
                        rowsPerPageOptions={[25,50,100]}
                    />
                </div>
            </div>
            </Fragment>
        )
        }
    // If state people array is not populated this returns
        else
            return <Spinner />
}

export default EncountersDG