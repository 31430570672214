import { useEffect, Fragment } from 'react'
import { useAppDispatch, useAppSelector } from "../../hooks"
import { useParams } from 'react-router-dom'

// context
import { FhirResourceState, getMedicalDevicesAsync} from './fhirResourceSlice'
import { PeopleState, getPersonAsync, setError } from "../people/peopleSlice"

//import local component
import ChartHeader from '../people/ChartHeader'
import MedicalDevicesDG from './MedicalDevicesDG'

const MedicalDevices = () => {
    const { id } = useParams()

    const { medicalDevices } = useAppSelector(FhirResourceState)
    const { person } = useAppSelector(PeopleState)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (!id) {
            dispatch(setError('Route Path Parameter For Person ID Missing'))
        } else {
            dispatch(getPersonAsync(id))
            dispatch(getMedicalDevicesAsync(id))
        }
    }, [dispatch, id])

    // console.log(medicalDevices)

        return (
            <Fragment>
                {person &&  <div style={{marginLeft: 10, marginTop: "30px"}}>
                                <ChartHeader person={person} />
                                <h1>Medical Devices</h1>
                                <MedicalDevicesDG medicalDevices={medicalDevices}/>
                            </div>}
            </Fragment>
        )
}

export default MedicalDevices