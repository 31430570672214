import { Fragment } from 'react'

const ContentWithHeader = ({header, content}: {header: string, content: JSX.Element}) => {
    return (
        <Fragment>
            <h1>{header}</h1>
            {content}
        </Fragment>
    )
}

export default ContentWithHeader