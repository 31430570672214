import { ChangeEvent, Fragment, useState } from 'react';
import { useAppDispatch, useAppSelector } from "../../hooks"

import validator from 'validator';

import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    Select,
    SelectChangeEvent,
    TextField,
    Tooltip
} from "@mui/material";

import PaperComponent from '../../widgets/PaperComponent';
import Separator from '../../widgets/Separator';
import ShowAlert from '../../widgets/ShowAlert';

// context
import useEnhancedStates from '../../utils/enhancedStates';
import { PeopleState, searchEndatoAddressAsync, clearError, setErrorAsync } from './peopleSlice';

const SearchEndatoAddress = () => {

    const { loading, error, person } = useAppSelector(PeopleState)
    const dispatch = useAppDispatch()

    const initialState = {
        open: false,
        firstName: person?.firstName || '',
        middleName: person?.middleName || '',
        lastName: person?.lastName || '',
        street: person?.Addresses?.[0]?.line1 || '',
        street2: person?.Addresses?.[0]?.line2 || '',
        region: person?.Addresses?.[0]?.state || '',
        postalCode: person?.Addresses?.[0]?.postalCode || '',
        city: person?.Addresses?.[0]?.city || '',
        dob: person?.birthDate || '',
        phone: person?.ContactPoints.filter(c => c.system === 'phone').map(c => c.value)[0] || '',
        email: person?.ContactPoints.filter(c => c.system === 'email').map(c => c.value)[0] || ''
    }

    const [dialog, setDialog] = useState(initialState)
    let { open, firstName, middleName, lastName, street, street2, region, postalCode, city, dob, phone, email } = dialog

    const handleClickOpen = () => { setDialog({...dialog, open: true}) }
    const handleClose = () => {
        dispatch(clearError())
        setDialog(initialState)
    }

    const handleSubmit = () => {
        const endatoSearchPayload = {firstName, middleName, lastName, street, street2, region, postalCode, city, dob, phone, email}

        if(!validator.isPostalCode(postalCode,'US')){
            dispatch(setErrorAsync('Postal Code is invalid!'))
            return
        }
        if(!person){
            dispatch(setErrorAsync('Missing Person to search for addresses on!'))
            return
        }
        dispatch(clearError())
        dispatch(searchEndatoAddressAsync(person,endatoSearchPayload))
        setDialog(initialState)
    }
    
    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => setDialog({...dialog, [e.target.name]: e.target.value})

    const stateOptions = useEnhancedStates()

    if (!loading) return (
        <Fragment>
            <IconButton color='inherit' onClick={handleClickOpen}>
                <Tooltip title='Search Endato Address'>
                    <SearchSharpIcon fontSize='medium' style={{color: '#000032'}}/>
                </Tooltip>
            </IconButton>                   
 
        <Dialog open={open} id="searchEndatoAddressModal" onClose={handleClose} aria-labelledby={'form-dialog-title'} fullWidth={true} maxWidth={'md'} PaperComponent={PaperComponent}>
            <DialogTitle id='form-dialog-title'>Search Endato Address</DialogTitle>
            <DialogContent>

                <DialogContentText>
                    {
                        error && <ShowAlert severity={'error'}>{error}</ShowAlert>
                    }
                </DialogContentText>

                <Separator />

                <Grid container alignItems={'center'} spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            margin='dense'
                            type='text'
                            id='firstName'
                            name='firstName'
                            label='First Name'
                            value={firstName}
                            onChange={onChange}
                            variant='outlined'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                    <TextField
                            margin='dense'
                            type='text'
                            id='middleName'
                            name='middleName'
                            label='Middle Name'
                            value={middleName}
                            onChange={onChange}
                            variant='outlined'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                    <TextField
                            margin='dense'
                            type='text'
                            id='lastName'
                            name='lastName'
                            label='Last Name'
                            value={lastName}
                            onChange={onChange}
                            variant='outlined'
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            margin='dense'
                            type='text'
                            id='street'
                            name='street'
                            label='Street Address'
                            value={street}
                            onChange={onChange}
                            variant='outlined'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            margin='dense'
                            type='text'
                            id='street2'
                            name='street2'
                            label='Address Line 2'
                            value={street2}
                            onChange={onChange}
                            variant='outlined'
                            fullWidth
                        />
                    </Grid>
                    
                    <Grid item xs={12} sm={4}>
                        <TextField
                            margin='dense'
                            type='text'
                            id='city'
                            name='city'
                            label='City'
                            value={city}
                            onChange={onChange}
                            variant='outlined'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl variant="outlined" margin='dense'>
                            <InputLabel htmlFor="outlined-age-native-simple">State</InputLabel>
                            <Select
                                native
                                value={region}
                                onChange={onChange}//{(val) => handleChange(val)}
                                label="State"
                                inputProps={{
                                    name: 'region',
                                    id: 'region',
                                }}
                                >
                                <option aria-label="None" value="" />
                                {stateOptions.map(item => {
                                    return (<option key={item.key} value={item.value}>{item.label}</option>);
                                    })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            margin='dense'
                            type='text'
                            id='postalCode'
                            name='postalCode'
                            label='Postal Code'
                            value={postalCode}
                            onChange={onChange}
                            variant='outlined'
                            fullWidth
                            required
                        />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <TextField
                            margin='dense'
                            type='text'
                            id='dob'
                            name='dob'
                            label='D.O.B'
                            value={dob}
                            onChange={onChange}
                            variant='outlined'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                    <TextField
                            margin='dense'
                            type='text'
                            id='email'
                            name='email'
                            label='Email'
                            value={email}
                            onChange={onChange}
                            variant='outlined'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                    <TextField
                            margin='dense'
                            type='text'
                            id='phone'
                            name='phone'
                            label='Phone'
                            value={phone}
                            onChange={onChange}
                            variant='outlined'
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} color={'primary'}>
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color={'primary'}>
                    Search
                </Button>
            </DialogActions>
        </Dialog>
    </Fragment>)
    else 
        return (<div></div>)
}
export default SearchEndatoAddress