import React, { Fragment, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import moment from 'moment'

// state
import { PeopleState, getPeopleAsync } from '../people/peopleSlice'

import Spinner from '../../layouts/Spinner'

// MUI
import { DataGrid, 
    GridToolbar,
    getGridStringOperators,
    GridColDef
 } from '@mui/x-data-grid';

import { Link, Tooltip } from "@mui/material"
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';
import PersonSharpIcon from '@mui/icons-material/PersonSharp';
import { People } from '../../models/people'





const CommonwellLinkingDG = () => {
    const dispatch = useAppDispatch()
    //Destructure of People State elements
    const { people, pageSize, /*page,*/ previouslyLoaded  } = useAppSelector(PeopleState)

    useEffect(() => {
        dispatch(getPeopleAsync())
    }, [dispatch, pageSize, /*page*/])


    //Defines People Data Grid columns
    const columnsFormat: GridColDef[] = React.useMemo( () => ([
        {field: 'viewDetails', headerName: '', width: 50, sortable: false, align: 'center',filterable: false,
            renderCell: (params) => {
                return <Link href={"/people/"+ params.row.id}><Tooltip title='View Details'><PersonSharpIcon /></Tooltip></Link>
            }},
        {field: 'firstName', headerName: 'First Name', width: 150,
            renderCell: (params) => {
                return params.row.firstName
            }},
        {field: 'lastName', headerName: 'Last Name', width: 150,
            renderCell: (params) => {
                return params.row.lastName
            }},
        {field: 'createdAt', headerName: 'Date Added', width: 150,
            renderCell: (params) => {
                return moment(params.row.createdAt).isValid() ? moment(params.row.createdAt).format('YYYY-MM-DD') : ''
            }},
        {field: 'cwLink', headerName: 'CW Linked?', width: 100, filterable: false,sortable: false,
            renderCell: (params) => {
                return params.row.isCwEnrolled ? <CheckCircleSharpIcon style={{color: 'green'}} />: <HighlightOffSharpIcon style={{color: 'red'}} />
            }},
        {field: 'address1', headerName: 'Address Line 1', width: 250, filterable: false,sortable: false,
        renderCell: (params) => {
            return params.row.line1
        }
            // valueGetter: (params) => {
            //     let result = [];
            //     if (params.row.Addresses) {
            //         if (params.row.Addresses[0].line1) {
            //         result.push(params.row.Addresses[0].line1);
            //         }
            //     } else {
            //         result = [""];
            //     }
            //     return result.join(", ");
            // }
        },
        {field: 'address2', headerName: 'Address Line 2', width: 200, filterable: false,sortable: false,
        renderCell: (params) => {
            return params.row.line2
        }
            // valueGetter: (params) => {
            //     let result = [];
            //     if (params.row.Addresses) {
            //         if (params.row.Addresses[0].line2) {
            //         result.push(params.row.Addresses[0].line2);
            //         }
            //     } else {
            //         result = [""];
            //     }
            //     return result.join(", ");
            // }
        },
        { field: 'city', headerName: 'City', width: 200, filterable: false,sortable: false,
        renderCell: (params) => {
            return params.row.city
        }
            // valueGetter: (params) => {
            //     let result = [];
            //     if (params.row.Addresses) {
            //         if (params.row.Addresses[0].city) {
            //         result.push(params.row.Addresses[0].city);
            //         }
            //     } else {
            //         result = [""];
            //     }
            //     return result.join(", ");
            // }
        },
        {field: 'state', headerName: 'State', width: 150, filterable: false,sortable: false,
        renderCell: (params) => {
            return params.row.state
        }
            // valueGetter: (params) => {
            //     let result = [];
            //     if (params.row.Addresses) {
            //         if (params.row.Addresses[0].state) {
            //         result.push(params.row.Addresses[0].state);
            //         }
            //     } else {
            //         result = [""];
            //     }
            //     return result.join(", ");
            // }
        },
        {field: 'postalCode', headerName: 'Zip Code', width: 150, filterable: false,sortable: false,
        renderCell: (params) => {
            return params.row.postalCode
        }
            // valueGetter: (params) => {
            //     let result = [];
            //     if (params.row.Addresses) {
            //         if (params.row.Addresses[0].postalCode) {
            //         result.push(params.row.Addresses[0].postalCode);
            //         }
            //     } else {
            //         result = [""];
            //     }
            //     return result.join(", ");
            // }
        },
        { field: 'email', headerName: 'Email', width: 300, filterable: false,sortable: false,
        renderCell: (params) => {
            return params.row.email
        }
        //     valueGetter: (params) => {
        //         let result = [];
        //         if (params.row.ContactPoints) {
        //             params.row.ContactPoints.forEach((point) => {
        //             if (point.system === 'email') {
        //                 result.push(point.value);
        //             }
        //         })
        //         } else {
        //             result = [""];
        //         }
        //         return result.join(", "); 
        // }
    },
        { field: 'phone', headerName: 'Phone', width: 150, filterable: false,sortable: false,
        renderCell: (params) => {
            return params.row.phone
        }
        //     valueGetter: (params) => {
        //         let result = [];
        //         if (params.row.ContactPoints) {
        //             params.row.ContactPoints.forEach((point) => {
        //             if (point.system === 'phone') {
        //                 result.push(point.value);
        //             }
        //         })
        //         } else {
        //             result = [""];
        //         }
        //         return result.join(", "); 
        // }
    },
        { field: 'birthDate', headerName: 'Birth Date', width: 150,
            renderCell: (params) => {
                return params.row.birthDate
            }}
    ]),
    [])

    const columns = React.useMemo( () =>
          columnsFormat.map((col) => {
            return {
              ...col,
              filterOperators: getGridStringOperators().filter(
                (operator) => operator.value === 'contains' || operator.value === 'equals',
              ),
            };
          }),
        [columnsFormat],
      );

    if(people.length > 0 || previouslyLoaded) {

        let cwPeople = people.filter((p: People) => {return !p.cwLink})

        return (<Fragment>
            <div>
                <div style={{ height: 650, width: '100%' }}>
                    <DataGrid
                        components={{
                            Toolbar: GridToolbar
                        }}
                        componentsProps={{
                            toolbar: {
                              csvOptions: { disableToolbarButton: true },
                              printOptions: { disableToolbarButton: true },
                            },
                        }}
                        style={{
                            backgroundColor: 'white'
                        }}
                        rows={cwPeople}
                        columns={columns}
                        rowsPerPageOptions={[25,50,100]}
                        
                    />
                </div>
            </div>
            </Fragment>
        )
        }
    // If state people array is not populated this returns
        else
            return <Spinner />
}


export default CommonwellLinkingDG