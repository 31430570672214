import { configureStore, ThunkDispatch } from '@reduxjs/toolkit';

import loggerMiddleware from './middleware/logger'
import rootReducer from './reducers'

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(loggerMiddleware),
    devTools: process.env.REACT_APP_SERVER_ENV !== 'production'
});

export type RootState = ReturnType<typeof store.getState>

// Update AppDispatch to handle thunks (using ThunkDispatch)
export type AppDispatch = ThunkDispatch<RootState, unknown, any>;

export type AppStore = typeof store