import { useStates } from 'react-us-states';

const useEnhancedStates = () => {
    const stateOptions = useStates().map((reg, index) => {
        return {
            label: reg.name,
            value: reg.abbreviation,
            key: index.toString()
         }
    })
    
    stateOptions.push({ label: 'AA - Armed Forces the Americas',
                        value: 'AA',
                        key: 'AA'})
    stateOptions.push({ label: 'AE - Armed Forces Europe',
                        value: 'AE',
                        key: 'AE'})
    stateOptions.push({ label: 'AP - Armed Forces Pacific',
                        value: 'AP',
                        key: 'AP'})

    return stateOptions
}
export default useEnhancedStates