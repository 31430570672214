import React, { Fragment, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from "../../hooks"
import moment from 'moment'

// state
import { RequestsState, loadRequestsAsync, setPageSizeAsync, setPageAsync, setSort } from './requestSlice'

import Spinner from '../../layouts/Spinner'

// MUI
import { DataGrid, 
    GridToolbar,
    getGridStringOperators, getGridDateOperators, GridColDef
 } from '@mui/x-data-grid';

import { Link, Tooltip } from "@mui/material"
import PersonSharpIcon from '@mui/icons-material/PersonSharp';

const DATE_LIMIT = moment().subtract(14,'days').format('YYYY-MM-DD')

const RequestsDG = () => {
    const dispatch = useAppDispatch()
    //Destructure of People State elements
    const { requests, sort  } = useAppSelector(RequestsState)
    let filter = React.useMemo(() => ([{columnField: 'createdAt', operatorValue: 'after', value:DATE_LIMIT}]), [])


    useEffect(() => {
        dispatch(loadRequestsAsync(filter, sort))
    }, [dispatch, filter, sort])



    // const setPageSize = (newPageSize) => {
    //     dispatch(setPageSizeAsync(newPageSize))
    // }

    // const setPage = (newPage) => {
    //     dispatch(setPageAsync(newPage))
    // }

    // const onFilterChange = React.useCallback((filterModel) => {
    //     filter = filterModel.items
    //     // filter.push({columnField: 'createdAt', operatorValue: 'after', value:DATE_LIMIT})
    //     dispatch(loadRequestsAsync(page,pageSize,filter))
    //   }, []);

    // const handleSortModelChange = React.useCallback((sortModel) => {
    //     dispatch(setSort(sortModel))
    //     dispatch(loadRequestsAsync(page,pageSize,filter,sortModel))
    //   }, []);


    //Defines Requests Data Grid columns
    const columnsFormat: GridColDef[] = React.useMemo( () => ([
        {field: 'viewDetails', headerName: '', width: 50, sortable: false, align: 'center',filterable: false,
            renderCell: (params) => {
                return <Link href={"/people/"+ params.row.personId} ><Tooltip title='View Details'><PersonSharpIcon /></Tooltip></Link>
            }},
        {field: 'firstName', headerName: 'First Name', width: 150, 
            renderCell: (params) => {
                return params.row.firstName
            }},
        {field: 'lastName', headerName: 'Last Name', width: 150, 
            renderCell: (params) => {
                return params.row.lastName
            }},
        {field: 'createdAt', headerName: 'Request Date', width: 150,
            renderCell: (params) => {
                return moment(params.row.createdAt).isValid() ? moment(params.row.createdAt).format('YYYY-MM-DD') : ''
            }},
        {field: 'status', headerName: 'Status', width: 100,
            renderCell: (params) => {
                if(params.row.status === 200) return 'Complete'
                if(params.row.status === 202) return 'In Progress'
                return 'Failed'
            }},
        {field: 'recordCount', headerName: 'Records Returned', width: 150, 
            renderCell: (params) => {
                return params.row.recordCount
            }}
    ]),
    [])

    const columns = React.useMemo( () =>
        columnsFormat.map((col) => {
          return {
            ...col,
            filterOperators: [...getGridStringOperators().filter(
              (operator) => operator.value === 'contains' || operator.value === 'equals',
            ), ...getGridDateOperators()],
          };
        }),
      [columnsFormat],
    );

    if(!!requests) {
        return (requests.length > 0) ?
            (<Fragment>
                <div>
                    <div style={{ height: 650, width: '100%' }}>
                        <DataGrid
                            components={{
                                Toolbar: GridToolbar
                            }}
                            componentsProps={{
                                toolbar: {
                                csvOptions: { disableToolbarButton: true },
                                printOptions: { disableToolbarButton: true },
                                },
                            }}
                            style={{
                                backgroundColor: 'white'
                            }}
                            initialState={{
                                sorting: {
                                sortModel: [{ field: 'createdAt', sort: 'desc' }],
                                },
                            }}
                            rows={requests}
                            // rowCount={requestCount}
                            columns={columns}
                            // page={page}
                            // onPageChange={(newPage) => setPage(newPage)}
                            // pageSize={pageSize}
                            rowsPerPageOptions={[25,50,100]}
                            // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            // paginationMode="server" 
                            // filterMode='server'
                            // onFilterModelChange={onFilterChange}
                            // sortingMode="server"
                            // onSortModelChange={handleSortModelChange}
                            
                        />
                    </div>
                </div>
            </Fragment>)
            :
            (<Fragment><div>No Requests Made In Last Two Weeks</div></Fragment>)
        }
    // If state requests array is not populated this returns
        else
            return <Spinner />
}


export default RequestsDG