export const capitalizeFirstLetter: (string: string) => string = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export const httpStatusCodeText: (code: number) => string = (code: number) => {
    switch(code) {
        case 200: return "Complete"
        case 202: return "In Progress"
        case 404: return "Not Found"
        case 429: return "Too Many Requests"
        case 454: return "Timed Out"
        case 500: return "Error"
        default: return "Unknown Status"
    }
}

export const highlightText: (string: string, color: string) => JSX.Element = (string: string, color: string) => {
    return <span style={{backgroundColor: color}}> {string}</span>
}