import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

// components
import AllergyIntolerance from './features/fhirResources/AllergyIntolerances'
import ArchivePatientsDG from './features/archived/ArchivePatientsDG'
import Batch from './features/batches/Batch'
import BatchDetails from './features/batches/BatchDetails'
import CarePlans from './features/fhirResources/CarePlans'
import CommonwellLinkingDG from './features/commonwellLinking/CommonwellLinkingDG'
import Conditions from './features/fhirResources/Conditions'
import ContentWithHeader from './layouts/ContentWithHeader'
import Encounters from './features/fhirResources/Encounters'
import FamilyHistory from './features/fhirResources/FamilyHistory'
import HGViewer from './layouts/HGViewer'
import Home from './layouts/Home'
import Logout from './layouts/Logout'
import MedicalDevices from './features/fhirResources/MedicalDevices'
import Medications from './features/fhirResources/Medications'
import MergePatients from './features/mergePatients/MergePatients'
import PeopleDG from './features/people/PeopleDG'
import PersonDetail from './features/people/PersonDetail'
import Procedures from './features/fhirResources/Procedures'
import ReciprocalDataDG from './features/reciprocalData/ReciprocalDataDG'
import Referrals from './features/referrals/Referrals'
import RequestsDG from './features/requests/RequestsDG'
import Template from './layouts/Template'
import Unauthorized from './layouts/Unauthorized'

// mui
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";

import { withAuthenticationRequired } from '@auth0/auth0-react'

import './App.css'

const theme = createTheme({
    palette: {
        background: {
            // default: "#000032"
        },
        primary: {
            main: '#000032',
        },
        secondary: {
            main: '#94c4f5',
        }
    }
})

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Routes>
                    <Route path='/' element={<Template />}>
                        <Route index element={<Home />} />
                        <Route path={'/archive'} element={<ContentWithHeader header="Archived Patients" content={<ArchivePatientsDG />} />} />
                        <Route path={'/batches'} element={<Batch />} />
                        <Route path={'/batches/:id'} element={<BatchDetails />} />
                        <Route path={'/commonwellLink'} element={<ContentWithHeader header="Patients Requiring Linking" content={<CommonwellLinkingDG />} />} />
                        <Route path={'/merge'} element={<MergePatients />} />
                        <Route path={'/people'} element={<ContentWithHeader header="Patient Management" content={<PeopleDG />} />} />
                        <Route path={'/people/:id'} element={<PersonDetail />} />
                        <Route path={'/people/:id/allergy'} element={<AllergyIntolerance />} />
                        <Route path={'/people/:id/careplans'} element={<CarePlans />} />
                        <Route path={'/people/:id/conditions'} element={<Conditions />} />
                        <Route path={'/people/:id/encounters'} element={<Encounters />} />
                        <Route path={'/people/:id/familyHistory'} element={<FamilyHistory />} />
                        <Route path={'/people/:id/medications'} element={<Medications />} />
                        <Route path={'/people/:id/medicalDevices'} element={<MedicalDevices />} />
                        <Route path={'/people/:id/procedures'} element={<Procedures />} />
                        <Route path={'/referrals'} element={<Referrals />} />
                        <Route path={'/reciprocalData'} element={<ContentWithHeader header="Patients Requiring Reciprocal Data" content={<ReciprocalDataDG />} />} />
                        <Route path={'/requests'} element={<ContentWithHeader header="Record Requests" content={<RequestsDG />} />} />
                        <Route path={'/logout'} element={<Logout />} />
                        <Route path={'/data-viewer'} element={<HGViewer />} />
                    </Route>
                    <Route path='/Unauthorized' element={<Unauthorized />}></Route>
                </Routes>
            </Router>
        </ThemeProvider>
    )
}

export default withAuthenticationRequired(App)