import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react'

import { Provider } from 'react-redux'
import { store } from './configureStore'

const appRoot = document.getElementById("app");
if (!appRoot) {
  throw new Error("App root element not found. Ensure your HTML has a valid element with id 'app'.");
}

const domain: string = process.env.REACT_APP_DOMAIN ?? "";
const clientId: string = process.env.REACT_APP_CLIENT_ID ?? "";

if (!domain || !clientId) {
  throw new Error("Missing required Auth0 environment variables.");
}

const root = ReactDOM.createRoot(appRoot);
root.render(
    <React.StrictMode>
        <Auth0Provider domain={domain}
                       audience={process.env.REACT_APP_AUD}
                       clientId={clientId}
                       redirectUri={window.location.origin}
                       scope={process.env.REACT_APP_SCOPE}
                       >
            <Provider store={store}>
                <App />
            </Provider>
        </Auth0Provider>
    </React.StrictMode>
);


