import axios from "axios"
import fileDownload from "js-file-download"
import moment from 'moment'
import { Fragment, MouseEventHandler } from 'react'
import { useSelector } from "react-redux"

// mui
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Chip, Tooltip, Button
} from "@mui/material";
import { GetApp, QueryBuilder, Done, Error, Launch } from "@mui/icons-material";
import {BatchState} from './batchSlice'
import Spinner from "../../layouts/Spinner"
import { Batches } from "../../models/batches"


const BatchTable = () => {
    const { batches } = useSelector(BatchState)

    const downloadFile: MouseEventHandler<HTMLButtonElement> = async evt => {
        const importId = evt.currentTarget.getAttribute('data-importid')
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/upload/${importId}/$download`, {responseType: 'blob'})

        const fileName = res.headers['content-disposition'].split("filename=")[1].replaceAll('"', '')
        fileDownload(res.data, fileName)
    }


    const headers = [
        {
            property: 'createdAt' as keyof Batches, label: 'Date', getValue: function (obj: Batches) {
                return moment(obj[this.property] as string).format("MM/DD/YYYY")
            }
        },
        {
            property: 'status', label: 'Upload Status', getValue: function(obj: Batches) {
                const status = obj.status
                switch (status) {
                    case 'running':
                        return <Chip icon={<QueryBuilder />} label={status} variant={'outlined'} />
                    case 'complete':
                        return <Chip icon={<Done style={{color: '#2e7d32'}} />} label={status} variant={'outlined'}  />
                    case 'failed':
                        return <Chip icon={<Error style={{color: 'ef5350'}} />} label={status} variant={'outlined'}  />
                    default:
                        return status
                }
            }
        },
        {
            property: 'fileName' as keyof Batches, label: 'File Name', getValue: function (obj: Batches) {
                return <Fragment><IconButton size='small' data-importid={obj.id}
                                             onClick={downloadFile}><GetApp/></IconButton> {obj[this.property] as keyof Batches}
                </Fragment>
            }
        },
        {
            property: 'isValid' as keyof Batches, label: 'File Valid?', getValue: function (obj: Batches) {
                return obj[this.property] ? "Y" : "N"
            }
        },
        {
            property: 'lines' as keyof Batches, label: 'Lines in File', getValue: function (obj: Batches) {
                return obj[this.property] as number
            }
        },
        {
            property: 'createdLines' as keyof Batches, label: 'Patients Created', getValue: function (obj: Batches) {
                if (obj.status === 'running') return "-"
                return obj[this.property] as number
            }
        },
        {
            property: 'existingPatient', label: 'Pre-Existing Patients', getValue: function (obj: Batches) {
                if (obj.status === 'running') return "-"
                return obj.summary.existing
            }
        },
        {
            property: 'errorPatients', label: 'Patient Errors', getValue: function (obj: Batches) {
                if (obj.status === 'running') return "-"
                return obj.summary.error
            }
        },
        {
            property: 'error', label: '', getValue: function (obj: Batches) {
                // return obj.isValid ? obj.errorDetails.join("; ") : obj[this.property]
                return <Button size='small' href={"/batches/"+ obj.id}><Tooltip title='View Details'><Launch /></Tooltip> View Details</Button>
            }
        },
    ]

    if(batches){
        return <TableContainer component={Paper} style={{marginTop: '30px'}}>

        <Table sx={{minWidth: 650}} size="small">

            <TableHead>
                <TableRow>
                    {headers.map(h => <TableCell key={h.property}>{h.label}</TableCell>)}
                </TableRow>
            </TableHead>
            <TableBody>
                {batches.map(b => {
                    return <TableRow key={b.id} id={b.id}>
                        {headers.map(h => {
                            return <TableCell key={`${b.id}${h.property}`}>{h.getValue(b)}</TableCell>
                        })}
                    </TableRow>
                })
                }
            </TableBody>
        </Table>
    </TableContainer>
    }
    else return <Spinner />
}

export default BatchTable