import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
import { AppDispatch, RootState } from '../../configureStore';
import { Files } from '../../models/files';
import { ReciprocalFiles } from '../../models/reciprocalFiles';

export const slice = createSlice({
    name: 'patientQuery',
    initialState: {
        loading: true,
        filesLoading: true,
        queries: [],
        files: null as Files | null,
        isTokenSet: false,
        error: null,
        recentRequest: null,
        reciprocalFiles: null as ReciprocalFiles | null
    },
    reducers: {
        setLoading: state => { state.loading = true },
        setFilesLoading: state => { state.filesLoading = true },
        setError: (state, action) => {
            state.error = action.payload
            state.loading = false
        },
        loadPatientQueries: (state, action) => {
            state.queries = action.payload
            state.recentRequest = action.payload[0]
            state.loading = false
        },
        loadPatientFiles: (state, action) => {
            state.files = action.payload
            state.filesLoading = false
        },
        loadReciprocalFiles: (state, action) => {
            state.reciprocalFiles = action.payload
            state.loading = false
        },
        resyncDocuments: state => {
            state.loading = false
        },
        clearError: state => { state.error = null },
    }
})

export const { setLoading, setError, clearError, loadPatientQueries, loadPatientFiles, loadReciprocalFiles, resyncDocuments } = slice.actions

export const  getQueriesByPatientAsync = (patientId: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoading())
    try {
        const params = { patientId }
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/PatientQuery`, { params })
        dispatch(loadPatientQueries(res.data))
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

export const  getPatientFilesAsync = (patientId: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoading())
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/PatientDocument?patientId=${patientId}`)
        dispatch(loadPatientFiles(res.data))
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

export const  downloadDocumentsAsync = (patientId: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoading())
    try {
        await axios.get(`${process.env.REACT_APP_API_BASE}/v1/Document/${patientId}`)
        dispatch(resyncDocuments())
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

export const  getReciprocalFilesAsync = (patientId: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoading())
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/PatientDocument/${patientId}/$files`)
        dispatch(loadReciprocalFiles(res.data))
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

export const sendNewPatientQueryAsync = (patientId: string) => async (dispatch: AppDispatch) => {
    // dispatch(setLoading())
    try {
        await axios.post(`${process.env.REACT_APP_API_BASE}/v1/PatientQuery`,{patientId})
        dispatch(getQueriesByPatientAsync(patientId))
    } catch (err) {
        console.log(err)
        if (axios.isAxiosError(err)) {
            dispatch(setError(err.response?.data.error))
        }
    }
}

export const PatientQueryState = (state: RootState) => state.patientQuery

export default slice.reducer