import React, { Fragment, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from "../../hooks"
import moment from 'moment'

// state
import { PeopleState, getReciprocalPatientsAsync } from '../people/peopleSlice'

import Spinner from '../../layouts/Spinner'

// MUI
import { DataGrid, 
    GridToolbar,
    getGridStringOperators,
    GridColDef
 } from '@mui/x-data-grid';

import { Link, Tooltip } from "@mui/material"
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';
import PersonSharpIcon from '@mui/icons-material/PersonSharp';


const ReciprocalDataDG = () => {
    const dispatch = useAppDispatch()
    //Destructure of People State elements
    const { reciprocalPatients, previouslyLoaded  } = useAppSelector(PeopleState)

    useEffect(() => {
        dispatch(getReciprocalPatientsAsync())
    }, [dispatch])


    //Defines People Data Grid columns
    const columnsFormat: GridColDef[] = React.useMemo( () => ([
        {field: 'viewDetails', headerName: '', width: 50, sortable: false, align: 'center',filterable: false,
            renderCell: (params) => {
                return <Link href={"/people/"+ params.row.id}><Tooltip title='View Details'><PersonSharpIcon /></Tooltip></Link>
            }},
        {field: 'firstName', headerName: 'First Name', width: 150,
            renderCell: (params) => {
                return params.row.firstName
            }},
        {field: 'lastName', headerName: 'Last Name', width: 150,
            renderCell: (params) => {
                return params.row.lastName
            }},
        {field: 'createdAt', headerName: 'Date Added', width: 150,
            renderCell: (params) => {
                return moment(params.row.createdAt).isValid() ? moment(params.row.createdAt).format('YYYY-MM-DD') : ''
            }},
        {field: 'returnedData', headerName: 'Reciprocal Data?', width: 100, filterable: false,sortable: false,
            renderCell: (params) => {
                return params.row.returnedData ? <CheckCircleSharpIcon style={{color: 'green'}} />: <HighlightOffSharpIcon style={{color: 'red'}} />
            }},
        { field: 'birthDate', headerName: 'Birth Date', width: 150,
            renderCell: (params) => {
                return params.row.birthDate
            }}
    ]),
    [])

    const columns = React.useMemo( () =>
          columnsFormat.map((col) => {
            return {
              ...col,
              filterOperators: getGridStringOperators().filter(
                (operator) => operator.value === 'contains' || operator.value === 'equals',
              ),
            };
          }),
        [columnsFormat],
      );

    if(reciprocalPatients) {

        return (<Fragment>
            <div>
                <div style={{ height: 650, width: '100%' }}>
                    <DataGrid
                        components={{
                            Toolbar: GridToolbar
                        }}
                        componentsProps={{
                            toolbar: {
                            //   csvOptions: { disableToolbarButton: true },
                              printOptions: { disableToolbarButton: true },
                            },
                        }}
                        style={{
                            backgroundColor: 'white'
                        }}
                        rows={reciprocalPatients}
                        columns={columns}
                        rowsPerPageOptions={[25,50,100]}
                        
                    />
                </div>
            </div>
            </Fragment>
        )
        }
    // If state people array is not populated this returns
        else
            return <Spinner />
}


export default ReciprocalDataDG