import { useEffect, Fragment } from 'react'
import { useAppDispatch, useAppSelector } from "../../hooks"
import UploadBatchForm from './UploadBatchForm'
import DownloadBatchCompositesForm from './DownloadBatchCompositesForm'
import BatchTable from './BatchTable'

import {BatchState, loadBatchesAsync, loadRateOptionsAsync, loadBatchZipsAsync, getBatchZipJobsAsync} from './batchSlice'
import Spinner from '../../layouts/Spinner'
import Separator from '../../widgets/Separator'
import BatchTemplate from './BatchTemplate'

const Batch = () => {
    const { loading } = useAppSelector(BatchState)
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(loadBatchesAsync())
        dispatch(loadRateOptionsAsync())
        dispatch(loadBatchZipsAsync())
        dispatch(getBatchZipJobsAsync())
    }, [dispatch])

    return (
        <Fragment>
            <h1>Batches</h1>
            {loading && <Spinner />}
            {!loading && <Fragment>
                <BatchTemplate />
                <Separator />
                <UploadBatchForm /> 
                <Separator />
                <DownloadBatchCompositesForm />
                <BatchTable />
            </Fragment>
            }
        </Fragment>
    )
}

export default Batch