import { Fragment } from 'react'

import SearchBar from './SearchBar'

// MUI
import {  
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarExport
} from '@mui/x-data-grid';

const CustomToolbar = (props: {setSearchQuery: (searchVal: string) => (searchValue: string) => void}) => (
    <Fragment>
      <GridToolbarContainer>
        {/* @ts-ignore - Need to upgrade @mui/x-data-grid */}
        <GridToolbarColumnsButton />
        <GridToolbarExport />
      </GridToolbarContainer>
      <SearchBar {...props} />
    </Fragment>
  );

export default CustomToolbar
