import { useEffect, Fragment } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from "../../hooks"

import moment from 'moment'

// components
import BatchDetailTable from './BatchDetailTable'
import Spinner from '../../layouts/Spinner'

// context
import { BatchState, loadBatchDetailsAsync, setError } from "./batchSlice"
import { Batch } from '../../models/batch'

const BatchDetails = () => {
    const dispatch = useAppDispatch()
    const { id } = useParams()

    const { batch, loading } = useAppSelector(BatchState)

    useEffect(() => {
        id ? dispatch(loadBatchDetailsAsync(id)) : dispatch(setError('Route Path Parameter For Batch ID Missing'))
    }, [dispatch, id])

    if (loading) return <Spinner />

    return (
        <Fragment>
            <h1>Batch Details</h1>
            <p>Import Id: {batch?.id}</p>
            <p>Created At: {moment(batch?.createdAt).format("YYYY-MM-DD HH:mm:ss")}</p>
            <p>Source: {batch?.source}</p>
            <p>Passed Validation: {batch?.isValid ? "Yes" : "No"}</p>
            <p>Lines: {batch?.summary.lines}</p>
            <p>Created/Existing/Error: {batch?.summary.created}/{batch?.summary.existing}/{batch?.summary.error}</p>

            {!!(batch) && <BatchDetailTable details={(batch as Batch).Details} batch={batch as Batch}/>}
        </Fragment>
    )
}

export default BatchDetails