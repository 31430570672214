import React, { Fragment, useEffect, useState } from 'react'

import Spinner from '../../layouts/Spinner'
import CustomToolbar from '../../widgets/CustomToolbar'

// MUI
import { Typography } from '@mui/material';
import { DataGrid, getGridStringOperators, GridColDef } from '@mui/x-data-grid';
import { FamilyHistory } from '../../models/fhirResources/familyHistory';
import { FamilyHistoryView } from '../../models/fhirResources/familyHistoryView';

const prepTableData =  (data: FamilyHistory[]) => {
    return data.filter(c => !!c.relationship).map(c => {
        const id = c.id
        const condition = c.condition?.length > 0 ? c.condition : []
        const relationship = c.relationship ? c.relationship : ''
        const bornDate = c.bornDate ? c.bornDate : ''
        const asserter = c.asserter ? c.asserter : ''
        const status = c.status ? c.status : ''
        const gender = c.gender ? c.gender : ''
        const deceased = c.deceased ? c.deceased : ''

        return {id,bornDate, condition,relationship, asserter, status, gender, deceased }
    })
}


const FamilyHistoryDG = ({familyHistory}: {familyHistory: FamilyHistory[] | null}) => {
    const [tableData, setTableData] = useState([] as FamilyHistoryView[]);

    useEffect(() => {
        setTableData(prepTableData(familyHistory || []))
    }, [familyHistory])

    //Defines Data Grid columns
    const columnsFormat: GridColDef[] = React.useMemo( () => ([
        {field: 'relationship', headerName: 'Relationship', width: 300,
            renderCell: (params) => {
                return(
                    <Typography variant="body2">
                        <span >{params.row.relationship.toUpperCase()}</span><br/>
                        {params.row.bornDate && <span style={{color:'#8a8988', fontSize:14}}>Born: {params.row.bornDate} {params.row.deceased && <span style={{color:'#8a8988', fontSize:14}}><br/>Deceased</span>}</span>}
                        {!params.row.bornDate && params.row.deceased && <span style={{color:'#8a8988', fontSize:14}}>Deceased</span>}
                    </Typography>
                )
            }},
        {field: 'asserter', headerName: 'Asserter', width: 300,
            renderCell: (params) => {
                return params.row.asserter.toUpperCase()
            }},
        {field: 'condition', headerName: 'Condition', width: 500, 
            renderCell: (params) => {
                if(params.row.condition === '') return 'No Condition Listed'
                else {
                return (<Fragment>
                            <div>
                                {params.row.condition.map((c: FamilyHistoryView['condition'][number], index: number) => {
                                    return(
                                            <Typography variant="body2" key={index}>
                                                <span >{c.condition.toUpperCase()}</span><br/>
                                                {c.onsetAge && <span style={{color:'#8a8988', fontSize:14}}>Onset Age: {c.onsetAge.value}{c.contributedToDeath && '; Cause of Death'}</span>}
                                                {!c.onsetAge && c.contributedToDeath && <span style={{color:'#8a8988', fontSize:14}}>Cause of Death</span>}
                                            </Typography>
                                        )
                                })}
                            </div>
                        </Fragment>)
                }
            }},
        {field: 'status', headerName: 'Status', width: 150, 
            renderCell: (params) => {
                return params.row.status.toUpperCase()
            }},
    ]),
    [])

    const columns = React.useMemo( () =>
          columnsFormat.map((col) => {
            return {
              ...col,
              filterOperators: getGridStringOperators().filter(
                (operator) => operator.value === 'contains' || operator.value === 'equals',
              ),
            };
          }),
        [columnsFormat],
      );

      const requestSearch = (searchValue: string) => {
        const searchRegex = new RegExp(`.*${searchValue}.*`, "ig");
        const filteredRows = tableData.filter((o) => {
          return (Object.keys(o) as (keyof FamilyHistoryView)[]).some((k) => {
            return searchRegex.test(o[k]?.toString() ?? '');
          });
        });
        setTableData(filteredRows);
      };
    

    if(tableData) {
        
        return (<Fragment>
            <div>
                <div style={{ height: 650, width: '100%' }}>
                    <DataGrid
                        components={{
                            Toolbar: CustomToolbar
                        }}
                        initialState={{
                          sorting: {
                            sortModel: [{ field: 'relationship', sort: 'asc' }],
                          },
                        }}
                        componentsProps={{
                            toolbar: {
                                setSearchQuery: (searchVal: string) => requestSearch(searchVal),
                            },
                        }}
                        style={{
                            backgroundColor: 'white'
                        }}
                        rows={tableData}
                        columns={columns}
                        rowsPerPageOptions={[25,50,100]}
                        getRowHeight={() => 'auto'}
                    />
                </div>
            </div>
            </Fragment>
        )
        }
    // If state people array is not populated this returns
        else
            return <Spinner />
}


export default FamilyHistoryDG