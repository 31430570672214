import React, { Fragment, useState, useEffect, MouseEventHandler, ChangeEventHandler, ChangeEvent } from 'react'
import { useAppDispatch, useAppSelector } from "../../hooks"
import axios from "axios"

// MUI
import {
    Button, Card,
    CardContent,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select, Snackbar,
    TextField,
    Typography,
    Autocomplete,
    FormControlLabel,
    Checkbox,
    SnackbarCloseReason,
    SelectChangeEvent
} from "@mui/material"
import Alert from '@mui/material/Alert';
import {BatchState, loadBatchesAsync, setError, setOpenForm} from './batchSlice'


const UploadBatchForm = () => {
    const dispatch = useAppDispatch()

    const {error, openForm, rateOptions} = useAppSelector(BatchState)

    const displayRate = rateOptions.length <= 1 ? 'none' : '' 

    const [state, setState] = useState({
        purpose: 'TREATMENT',
        selectedFile: null as File | null,
        fileError: error,
        rate: '',
        generateCoverPage: false,
    })

    useEffect(() => {
        if(openForm){
            setOpen(true)
        }
        if(rateOptions){
            const defaultRate = rateOptions.filter(a =>{ return a.isDefault === true} )
            const defaultRateValue = defaultRate[0]?.id

            setState({ ...state, rate: defaultRateValue })
        }
    }, [openForm,rateOptions])

    const { purpose, fileError, selectedFile } = state
    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }
    const setRate = (e: React.SyntheticEvent) =>{
        const val = rateOptions.filter(r => r.display === (e.target as HTMLInputElement).innerHTML)
        setState({ ...state, rate: val[0].id })
    }
    const onFileChange: ChangeEventHandler<HTMLInputElement> = (e: ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, selectedFile: e.target.files?.[0] ?? null })
        setDisableSubmit(false)
    }

    const onChangeCoverPage = (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setState({ ...state, generateCoverPage: checked })
    }

    /* alert */
    const [open, setOpen] = React.useState(false)
    const [disableSubmit, setDisableSubmit] = React.useState(true)
    const closeSnackbar = (event: React.SyntheticEvent<any> | Event, reason?: SnackbarCloseReason) => {
        if (reason === 'clickaway') {
            return
        }
        setState({ ...state, fileError: null})
        setOpen(false)
    }

    const onClick: MouseEventHandler<HTMLButtonElement> = async e => {
        e.preventDefault()
        if (!state.selectedFile) return;
        setDisableSubmit(true)
        const data = new FormData()
        const isCsv = (state.selectedFile.type === 'text/csv') || (state.selectedFile.type === 'application/vnd.ms-excel')

        if (!isCsv) {
            setState({ ...state, fileError: 'File type is not valid. File uploads must have a .csv file extension'})
            setOpen(true)
            return
        }

        data.append('file', state.selectedFile)
        data.append('purpose', state.purpose)
        data.append('rate', state.rate)
        data.append('generateCoverPage', state.generateCoverPage.toString())

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        try {
            await axios.post(`${process.env.REACT_APP_API_BASE}/v1/upload`, data, config).catch((error) => {
                if (error) {
                    if( error.response ){
                        console.log(`In UploadForm: ${error.response.data.message}`)
                        dispatch(setError(error.response.data.message))
                        dispatch(setOpenForm(true))
                    }
                }
            })
        } catch (ex) {
            console.log(ex)
        }
        dispatch(loadBatchesAsync())

    }


    return <Fragment>
        {/*Error Popup*/}
        {fileError && <Snackbar open={open} autoHideDuration={9000} style={{ whiteSpace: 'pre-wrap' }} onClose={closeSnackbar} anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
        }}>
            <Alert severity={'error'}>{fileError}</Alert>
        </Snackbar>}
        
        <Card variant={'outlined'}>
            <CardContent>
                <Typography sx={{ fontSize: 14 }} style={{marginBottom: '15px', textTransform: 'uppercase'}}  >
                    Upload New Patient File
                </Typography>
                <Grid container spacing={3}>

                    <Grid item xs={5}>
                        <FormControl fullWidth>
                            <TextField
                                id='filename-display'
                                value={selectedFile?.name || ''}
                                size={'small'}
                                onChange={onChange}
                                disabled
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={6} style={{display: 'none'}}>
                        <FormControl fullWidth disabled>
                            <InputLabel id="requestType-label">Request Type</InputLabel>
                            <Select
                                id="requestType"
                                name='purpose'
                                value={purpose}
                                onChange={onChange}
                            >
                                <MenuItem value={'TREATMENT'}>Treatment</MenuItem>
                                <MenuItem value={'REQUEST'}>Request</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>


                    <Grid item xs={2}>
                        <Button
                            variant="outlined"
                            component="label"
                            style={{marginTop: '4px'}}
                            size={'small'}
                        >
                            Choose File
                            <input
                                type="file"
                                name='file'
                                onChange={onFileChange}
                                hidden
                            />
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" style={{minWidth: 200, display: displayRate}} required>
                            <Autocomplete
                                // disablePortal
                                id="rate"
                                options={rateOptions.map(option=>option.display)}
                                sx={{ width: 300 }}
                                size={'small'}
                                onChange={setRate}
                                isOptionEqualToValue={(option, value) => option === value}
                                renderInput={(params) =>  <TextField {...params} label='Rate'/>}
                                />
                        </FormControl>     
                    </Grid>
                    <Grid item xs={12} style={{ paddingTop: 0 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.generateCoverPage}
                                    onChange={onChangeCoverPage}
                                    name="generateCoverPage"
                                    color="primary"
                                />
                            }
                            label="Generate cover page"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button onClick={onClick} variant={'contained'} color={'primary'} disabled={disableSubmit}>Upload</Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    </Fragment>
}

export default UploadBatchForm