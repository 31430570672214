import React, { Fragment, useEffect, useState } from 'react'
import moment from 'moment'

import RenderExpandableCell from '../../utils/RenderExpandableCell';
import Spinner from '../../layouts/Spinner'
import CustomToolbar from '../../widgets/CustomToolbar'

// MUI
import { DataGrid, getGridStringOperators, GridColDef } from '@mui/x-data-grid';
import { Procedure } from '../../models/fhirResources/procedure';
import { ProcedureView } from '../../models/fhirResources/procedureView';

const prepTableData =  (data: Procedure[]) => {
    return data.map(c => {
        const id = c.id
        const description = c.code?.text || ''
        const date = c.startDate ? moment(c.startDate).format('YYYY-MM-DD') : ''
        const status = c.status ? c.status : ''
        const performer = c.performer?.length > 0 ? c.performer[0] : ''
        const indication = c.reasonCode && c.reasonCode?.length > 0 ? c.reasonCode[0] : ''

        return {id,description, date, status,performer, indication}
    })
}


const ProceduresDG = ({procedures}: {procedures: Procedure[] | null}) => {
    const [tableData, setTableData] = useState([] as ProcedureView[]);

    useEffect(() => {
        setTableData(prepTableData(procedures || []))
    }, [procedures])

    const requestSearch = (searchValue: string) => {
        const searchRegex = new RegExp(`.*${searchValue}.*`, "ig");
        const filteredRows = tableData.filter((o) => {
          return (Object.keys(o) as (keyof ProcedureView)[]).some((k) => {
            return searchRegex.test(o[k]?.toString() ?? '');
          });
        });
        setTableData(filteredRows);
      };
    //Defines conditions Data Grid columns
    const columnsFormat: GridColDef[] = React.useMemo( () => ([
        {field: 'description', headerName: 'Description', width: 600,
            renderCell: (params) => {
                return <RenderExpandableCell value={params.row.description}/>
            }},
        {field: 'date', headerName: 'Date', width: 150,
            renderCell: (params) => {
                return params.row.date
            }},
        {field: 'status', headerName: 'Status', width: 150, 
            renderCell: (params) => {
                return params.row.status 
            }},
        {field: 'performer', headerName: 'Performer', width: 300, 
            renderCell: (params) => {
                return params.row.performer 
            }},
        {field: 'indication', headerName: 'Indication', width: 500, 
            renderCell: (params) => {
                return <RenderExpandableCell value={params.row.indication}/> 
            }}
    ]),
    [])

    const columns = React.useMemo( () =>
          columnsFormat.map((col) => {
            return {
              ...col,
              filterOperators: getGridStringOperators().filter(
                (operator) => operator.value === 'contains' || operator.value === 'equals',
              ),
            };
          }),
        [columnsFormat],
      );

    if(tableData) {
        
        return (<Fragment>
            <div>
                <div style={{ height: 650, width: '100%' }}>
                    <DataGrid
                        components={{
                            Toolbar: CustomToolbar
                        }}
                        componentsProps={{
                            toolbar: {
                                setSearchQuery: (searchVal: string) => requestSearch(searchVal),
                            },
                        }}
                        style={{
                            backgroundColor: 'white'
                        }}
                        rows={tableData}
                        columns={columns}
                        rowsPerPageOptions={[25,50,100]}
                    />
                </div>
            </div>
            </Fragment>
        )
        }
    // If state people array is not populated this returns
        else
            return <Spinner />
}


export default ProceduresDG