import React, { Fragment, useEffect, useState } from 'react'

import RenderExpandableCell from '../../utils/RenderExpandableCell';
import Spinner from '../../layouts/Spinner'
import CustomToolbar from '../../widgets/CustomToolbar'

// MUI
import { Typography } from '@mui/material';
import { DataGrid, getGridStringOperators, GridColDef } from '@mui/x-data-grid';
import { MedicalDevice } from '../../models/fhirResources/medicalDevice';
import { MedicalDeviceView } from '../../models/fhirResources/medicalDeviceView';

const prepTableData = (data: MedicalDevice[]) => {
    return data.map(c => {
        const id = c.id
        const device = c.device ? c.device : ''
        const note = c.note ? c.note : ''
        const quantity = c.quantity ? c.quantity : null
        const date = c.date ? c.date : ''
        const asserter = c.asserter ? c.asserter : ''
        const status = c.status ? c.status : ''
        const bodySite = c.bodySite ? c.bodySite : ''

        return {id,device, note, quantity, date, asserter, status, bodySite }
    })
}


const MedicalDevicesDG = ({medicalDevices}: {medicalDevices: MedicalDevice[] | null}) => {
    const [tableData, setTableData] = useState([] as MedicalDeviceView[]);

    useEffect(() => {
        setTableData(prepTableData(medicalDevices || []))
    }, [medicalDevices])


    //Defines conditions Data Grid columns
    const columnsFormat: GridColDef[] = React.useMemo( () => ([
        {field: 'asserter', headerName: 'Asserter', width: 300,
            renderCell: (params) => {
                return params.row.asserter.toUpperCase()
            }},
        {field: 'date', headerName: 'Date', width: 200,
            renderCell: (params) => {
                // return <RenderExpandableCell value={params.row.instruction.toUpperCase()}/>
                return (
                    <Typography variant="body2">
                            <span >{params.row.date.toUpperCase()}</span>
                        </Typography>
                )
            }},
        {field: 'device', headerName: 'Device', width: 400, 
            renderCell: (params) => {
                return (<Typography variant="body2">
                          <span >{params.row.device.toUpperCase()}</span>
                          {params.row.quantity && <span style={{color:'#8a8988'}} ><br/>Quantity: {params.row.quantity}</span>}
                      </Typography>)
            }},
        {field: 'status', headerName: 'Status', width: 150, 
            renderCell: (params) => {
                return params.row.status.toUpperCase()
            }},
        // {field: 'quantity', headerName: 'Quantity', width: 100, 
        //     renderCell: (params) => {
        //         return params.row.quantity
        //     }},
        {field: 'bodySite', headerName: 'Site', width: 400, 
            renderCell: (params) => {
                return params.row.bodySite
            }},
        {field: 'note', headerName: 'Note', width: 400,
            renderCell: (params) => {
                return <RenderExpandableCell value={params.row.note.toUpperCase()}/>
            }},
    ]),
    [])

    const columns = React.useMemo( () =>
          columnsFormat.map((col) => {
            return {
              ...col,
              filterOperators: getGridStringOperators().filter(
                (operator) => operator.value === 'contains' || operator.value === 'equals',
              ),
            };
          }),
        [columnsFormat],
      );

      const requestSearch = (searchValue: string) => {
        const searchRegex = new RegExp(`.*${searchValue}.*`, "ig");
        const filteredRows = tableData.filter((o) => {
          return (Object.keys(o) as (keyof MedicalDeviceView)[]).some((k) => {
            return searchRegex.test(o[k]?.toString() ?? '');
          });
        });
        setTableData(filteredRows);
      };
    

    if(tableData) {
        
        return (<Fragment>
            <div>
                <div style={{ height: 650, width: '100%' }}>
                    <DataGrid
                        components={{
                            Toolbar: CustomToolbar
                        }}
                        initialState={{
                          sorting: {
                            sortModel: [{ field: 'date', sort: 'asc' }],
                          },
                        }}
                        componentsProps={{
                            toolbar: {
                                setSearchQuery: (searchVal: string) => requestSearch(searchVal),
                            },
                        }}
                        style={{
                            backgroundColor: 'white'
                        }}
                        rows={tableData}
                        columns={columns}
                        rowsPerPageOptions={[25,50,100]}
                        getRowHeight={() => 'auto'}
                    />
                </div>
            </div>
            </Fragment>
        )
        }
    // If state people array is not populated this returns
        else
            return <Spinner />
}


export default MedicalDevicesDG