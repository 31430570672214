import { useEffect, Fragment } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import moment from 'moment'

// mui
import {Paper, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Typography} from '@mui/material'
import { CheckCircle, AccessTime, ErrorOutline } from '@mui/icons-material'

// components
import Spinner from '../../layouts/Spinner'

// context
import { PatientQueryState, getQueriesByPatientAsync } from '../patientQuery/patientQuerySlice'
import {loadRateOptionsAsync} from '../batches/batchSlice'

import { httpStatusCodeText } from '../../utils/formatting'
import SubmitPatientQuery from "../patientQuery/submitPatientQuery";
import RequestRateEdit from './RequestRateEdit'
import { Person } from '../../models/person'
import { Query } from '../../models/query'

const RequestHistory = ({person}: {person: Person}) => {
    const dispatch = useAppDispatch()

    const { id:patientId } = person

    useEffect(()=>{
        dispatch(loadRateOptionsAsync({update:true}))
    },[dispatch])

    useEffect(() => {
        if (patientId) dispatch(getQueriesByPatientAsync(patientId))
        
    }, [dispatch, patientId])

    const { loading, queries } = useAppSelector(PatientQueryState)

    const Title = () => {
        return <Typography variant={'h6'} style={{marginTop: '30px'}} >Request History</Typography>
    }

    if (loading) return (
        <Fragment>
            <Title />
            <Spinner />
        </Fragment>
    )

    const getIcon = (status: number) => {
        switch (status) {
            case 200: return <CheckCircle style={{color: 'green'}} />
            case 202: return <AccessTime style={{color: 'gray'}} />
            case 500:
            case 404:
            case 429:
            case 454: return <ErrorOutline style={{color: 'red'}} />
            default: return null
        }
    }

    const getStatus = (status: number) => {
        const text = httpStatusCodeText(status)

        const icon = getIcon(status)

        return <div style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        }}>
             {!!icon && icon}&nbsp;{text}
        </div>
    }

    return (
        <Fragment>
            <Title />
            <SubmitPatientQuery person={person} />
            {(!queries || queries.length === 0) && <p>No queries</p>}
            {(!!queries && queries.length > 0) && (
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left"></TableCell>
                                <TableCell align="left">Date/Time</TableCell>
                                <TableCell align="left">Status</TableCell>
                                <TableCell align="left">Rate</TableCell>
                                <TableCell align="left">Record Count</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {queries.map((row: Query) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="left">{<RequestRateEdit dataFromParent={row} />}</TableCell>
                                    <TableCell align="left">{moment(row.createdAt).isValid() ? moment(row.createdAt).format('YYYY-MM-DD') : ''}</TableCell>
                                    <TableCell align="left">{getStatus(row.status)}</TableCell>
                                    <TableCell align="left">{row.Rate.display}</TableCell>
                                    <TableCell align="left">{row.DataSourcePatientQuery?.recordCount}</TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Fragment>

    )
}

export default RequestHistory