import { Fragment, MouseEventHandler, useState } from 'react'
import { useAppDispatch, useAppSelector } from "../../hooks"

import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Grid,
    Tooltip,
    Button,
    Chip
} from "@mui/material"
import Alert from '@mui/material/Alert';

// context
import { PeopleState, clearError, unenrollPatientAsync} from './peopleSlice'

import PaperComponent from '../../widgets/PaperComponent';
import { Person } from '../../models/person';

const UnenrollPerson = ({person}: {person: Person}) => {

    const { error } = useAppSelector(PeopleState)
    const dispatch = useAppDispatch()

    const {id, firstName, lastName, isActive} = person

    const initialState = {
        open: false,
    }

    const [dialog, setDialog] = useState(initialState)
    const { open } = dialog

    const handleClickOpen: MouseEventHandler<HTMLDivElement> = () => { 
        dispatch(clearError())
        setDialog({...dialog, open: true}) }
    const handleClose: MouseEventHandler<HTMLButtonElement> = () => {
        dispatch(clearError())
        setDialog(initialState)
    }
    const handleSubmit = () => {
        dispatch(unenrollPatientAsync(id))
        console.log('clicked')
        setDialog(initialState)
    }

    return isActive ? (
        <Fragment>
            <Box display='flex' justifyContent='flex-mid' >
                <Tooltip title='Unenroll Patient' >
                    <Chip
                        variant="filled"
                        sx={{ marginTop: 1, marginLeft: 1 }}
                        color="primary"
                        size='small'
                        label="Unenroll"
                        onClick={handleClickOpen}
                    />
                </Tooltip>
            </Box>

            <Dialog open={open} id="setArchiveStatusModal" onClose={handleClose} aria-labelledby={'form-dialog-title'} fullWidth={true} maxWidth={'md'} PaperComponent={PaperComponent}>
            <DialogTitle id='form-dialog-title'>Unenroll Person From Network</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    { error && <Alert severity={'error'}>{error}</Alert> }
                </DialogContentText>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                    <DialogContentText>You are about to Unenroll {firstName} {lastName} </DialogContentText>
                    <DialogContentText>Do you wish to continue?</DialogContentText>
                    </Grid>   
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color={'primary'}>
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color={'primary'}>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
        </Fragment>
    ): null
}

export default UnenrollPerson