import {ChangeEvent, Fragment, useState} from 'react'
import { useAppDispatch, useAppSelector } from "../../hooks"

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Grid,
    TextField,
    Tooltip,
    Button,
    IconButton,
    FormControl,
    Autocomplete,
    SelectChangeEvent
} from "@mui/material"

import Alert from '@mui/material/Alert';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import EditOffIcon from '@mui/icons-material/EditOff';

// context
import { clearError, PeopleState, requestRateUpdateAsync, setError } from "./peopleSlice"
import {BatchState} from '../batches/batchSlice'
import { Query } from '../../models/query';

const RequestRateEdit = (props: {dataFromParent: Query}) => {

    const { error } = useAppSelector(PeopleState)
    const { rateOptions } = useAppSelector(BatchState)
    const dispatch = useAppDispatch()

    const initialState = {
        open: false,
        rate: props.dataFromParent.Rate.id,
        reason: ''

    }

    const [dialog, setDialog] = useState(initialState)
    const { open, rate,reason } = dialog

    const handleClickOpen = () => { 
        dispatch(clearError())
        setDialog({...dialog, open: true}) }
    const handleClose = () => {
        dispatch(clearError())
        setDialog(initialState)
    }
    const handleSubmit = () => {
        dispatch(clearError())
        if(reason === ''){
            dispatch(setError('Reason Required'))
            return
        }
        dispatch(requestRateUpdateAsync({id:props.dataFromParent.id,rate,reason}))
        setDialog(initialState)
    }

    const setRate = (e: React.SyntheticEvent) =>{
        const val = rateOptions.find(r => r.display === (e.target as HTMLInputElement).innerHTML)
        setDialog({ ...dialog, rate: val?.id ?? '' })
    }

    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => setDialog({...dialog, [e.target.name]: e.target.value})

    return (
        <Fragment>
            { props.dataFromParent.QueryRateUpdateRequests.length === 0 &&
                <IconButton color='inherit' onClick={handleClickOpen}>
                    <Tooltip title='Edit Billing Rate'>
                        <EditSharpIcon fontSize='small' style={{color: '#939393'}}/>
                    </Tooltip>
                </IconButton>    
            }               
            { props.dataFromParent.QueryRateUpdateRequests.length > 0 &&
            <Tooltip title='Rate Update Pending'>
                    <EditOffIcon fontSize='small' style={{color: '#939393', marginLeft:8}}/>
            </Tooltip>
            }
            <Dialog open={open} id="editContactPointModal" onClose={handleClose} aria-labelledby={'form-dialog-title'} fullWidth={true} maxWidth={'md'} > 
                <DialogTitle id='form-dialog-title'>Edit Billing Rate</DialogTitle>
                <DialogContent>
                <Grid item xs={12} >&nbsp;</Grid>
                    <DialogContentText>
                        { error && <Alert severity={'error'}>{error}</Alert> }
                    </DialogContentText>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                        <FormControl variant="outlined" style={{minWidth: 200}} >
                            <Autocomplete
                                id="rate"
                                value={rateOptions.find(r => r.id === rate)?.display}
                                options={rateOptions.map(option=>option.display)}
                                sx={{ width: 300 }}
                                size={'small'}
                                onChange={setRate}
                                isOptionEqualToValue={(option, value) => option === value}
                                renderInput={(params) =>  <TextField {...params} label='Rate'/>}
                                />
                        </FormControl>    
            </Grid>
            <Grid item xs={12}>  
            </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin='dense'
                                type='text'
                                id='reason'
                                name='reason'
                                label='Reason'
                                value={reason}
                                onChange={onChange}
                                variant='outlined'
                                fullWidth
                                required
                            />
                        </Grid>
                    
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color={'primary'}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color={'primary'}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}
export default RequestRateEdit