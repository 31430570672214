import { Fragment } from 'react'


// components
import ReferralsDG from './ReferralsDG'
import ReferralTable from './ReferralTable';

// MUI
import Grid from '@mui/material/Grid';


const Referrals = () => {

    return (
        <Fragment>
            <h1>Referrals</h1>
            <Grid container spacing={3} alignItems={'stretch'}>
                <Grid item xs={12} md={4} lg={4}>
                    <ReferralsDG />
                </Grid>
                <Grid item xs={12} md={8} lg={8}>
                    <ReferralTable />
                </Grid>
            </Grid>


        </Fragment>
    )
}

export default Referrals