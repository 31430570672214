import React from 'react';

import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardSharpIcon from '@mui/icons-material/DashboardSharp';
import Tooltip from '@mui/material/Tooltip';
import PersonSharpIcon from '@mui/icons-material/PersonSharp';
import UploadFileSharpIcon from '@mui/icons-material/UploadFileSharp';
import PeopleSharpIcon from '@mui/icons-material/PeopleSharp';
import InventoryIcon from '@mui/icons-material/Inventory';
import ArchiveIcon from '@mui/icons-material/Archive';
import HealingIcon from '@mui/icons-material/Healing';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import MedicationIcon from '@mui/icons-material/Medication';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import CachedIcon from '@mui/icons-material/Cached';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import { Link } from "react-router-dom";
import { DataSource } from '../models/dataSource';


export const mainNavigationItems = (open: boolean, userDataSource: DataSource) => {
    
    return (<div>
        
        <ListItem button component={Link} to='/'>
            <ListItemIcon>
            <Tooltip title={open ? '' : 'Home'} placement='right'>
                <DashboardSharpIcon />
            </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Home" />
        </ListItem>
        
        
        <ListItem button component={Link} to='/people' >
            <ListItemIcon>
            <Tooltip title={open ? '' : 'Patient Management'} placement='right'>
                <PersonSharpIcon />
            </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Patient Management"  />
        </ListItem>

        <ListItem button component={Link} to='/batches' >
            <ListItemIcon>
            <Tooltip title={open ? '' : 'Upload CSV File'} placement='right'>
                <UploadFileSharpIcon />
            </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Upload CSV File"  />
        </ListItem>

        <ListItem button component={Link} to='/commonwellLink' >
            <ListItemIcon>
            <Tooltip title={open ? '' : 'Patient Linking'} placement='right'>
                <PeopleSharpIcon />
            </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Patient Linking"  />
        </ListItem>

        <ListItem button component={Link} to='/reciprocalData' >
            <ListItemIcon>
            <Tooltip title={open ? '' : 'Reciprocal Data'} placement='right'>
                <CachedIcon />
            </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Reciprocal Data"  />
        </ListItem>
        
        <ListItem button component={Link} to='/requests' >
            <ListItemIcon>
            <Tooltip title={open ? '' : 'Requests'} placement='right'>
                <InventoryIcon />
            </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Requests"  />
        </ListItem>

        <ListItem button component={Link} to='/archive' >
            <ListItemIcon>
            <Tooltip title={open ? '' : 'Patient Archive'} placement='right'>
                <ArchiveIcon />
            </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Patient Archive"  />
        </ListItem>


        { userDataSource && userDataSource === DataSource.CW &&
            <ListItem button component={Link} to='/merge' >
            <ListItemIcon>
            <Tooltip title={open ? '' : 'Merge Patients'} placement='right'>
                <MergeTypeIcon />
            </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Merge Patients"  />
        </ListItem>
        }
 
    </div>)
}

export const secondaryListItems = (id: string, open: boolean) => {

    return(
        <div>
            <ListItem button component={Link} to={`/people/${id}`} >
                <ListItemIcon>
                    <Tooltip title={open ? '' : 'Patient Details'} placement='right'>
                        <ContactPageIcon />
                    </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Patient Details"  />
            </ListItem>
            <ListItem button component={Link} to={`/people/${id}/allergy`} >
                <ListItemIcon>
                    <Tooltip title={open ? '' : 'Allergies'} placement='right'>
                        <VaccinesIcon />
                    </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Allergies"  />
            </ListItem>

            <ListItem button component={Link} to={`/people/${id}/careplans`} >
                <ListItemIcon>
                    <Tooltip title={open ? '' : 'Care Plans'} placement='right'>
                        <FactCheckIcon />
                    </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Care Plans"  />
            </ListItem>

            <ListItem button component={Link} to={`/people/${id}/conditions`} >
                <ListItemIcon>
                    <Tooltip title={open ? '' : 'Conditions'} placement='right'>
                        <HealingIcon />
                    </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Conditions"  />
            </ListItem>

            <ListItem button component={Link} to={`/people/${id}/encounters`} >
                <ListItemIcon>
                    <Tooltip title={open ? '' : 'Encounters'} placement='right'>
                        <HealthAndSafetyIcon />
                    </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Encounters"  />
            </ListItem>

            <ListItem button component={Link} to={`/people/${id}/familyHistory`} >
                <ListItemIcon>
                    <Tooltip title={open ? '' : 'Family History'} placement='right'>
                        <FamilyRestroomIcon />
                    </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Family History"  />
            </ListItem>

            <ListItem button component={Link} to={`/people/${id}/medicalDevices`} >
                <ListItemIcon>
                    <Tooltip title={open ? '' : 'Medical Devices'} placement='right'>
                        <MonitorHeartIcon />
                    </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Medical Devices"  />
            </ListItem>

            <ListItem button component={Link} to={`/people/${id}/medications`} >
                <ListItemIcon>
                    <Tooltip title={open ? '' : 'Medications'} placement='right'>
                        <MedicationIcon />
                    </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Medications"  />
            </ListItem>

            <ListItem button component={Link} to={`/people/${id}/procedures`} >
                <ListItemIcon>
                    <Tooltip title={open ? '' : 'Procedures'} placement='right'>
                        <LocalHospitalIcon />
                    </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Procedures"  />
            </ListItem>
        </div>
    )
}