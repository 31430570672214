import { useEffect, Fragment } from 'react'
import { useAppDispatch, useAppSelector } from "../../hooks"
import { useParams } from 'react-router-dom'

// context
import { FhirResourceState, getFamilyHistoryAsync} from './fhirResourceSlice'
import { PeopleState, getPersonAsync, setError } from "../people/peopleSlice"

//import local component
import ChartHeader from '../people/ChartHeader'
import FamilyHistoryDG from './FamilyHistoryDG'

const FamilyHistory = () => {
    const { id } = useParams()

    const { familyHistory } = useAppSelector(FhirResourceState)
    const { person } = useAppSelector(PeopleState)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (!id) {
            dispatch(setError('Route Path Parameter For Person ID Missing'))
        } else {
            dispatch(getPersonAsync(id))
            dispatch(getFamilyHistoryAsync(id))
        }
    }, [dispatch, id])

        return (
            <Fragment>
                {person &&  <div style={{marginLeft: 10, marginTop: "30px"}}>
                                <ChartHeader person={person} />
                                <h1>Family History</h1>
                                <FamilyHistoryDG familyHistory={familyHistory}/>
                            </div>}
            </Fragment>
        )
}

export default FamilyHistory