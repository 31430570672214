import { Fragment, useState, useEffect, MouseEventHandler, ChangeEvent } from 'react'
import { useAppDispatch, useAppSelector } from "../../hooks"

import validator from 'validator'

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Grid,
    Select,
    InputLabel,
    FormControl,
    TextField,
    Tooltip,
    Button,
    IconButton,
    SelectChangeEvent
} from "@mui/material"
import AddHomeWorkSharpIcon from '@mui/icons-material/AddHomeWorkSharp';

import Separator from '../../widgets/Separator'
import ShowAlert from '../../widgets/ShowAlert'
import PaperComponent from '../../widgets/PaperComponent';

// context
import {PeopleState, addNewAddressAsync, clearError, setErrorAsync} from './peopleSlice'
import useEnhancedStates from '../../utils/enhancedStates';
import { SubmitType } from '../../models/submitType';

const AddAddress = () => {

const { loading, error, person } = useAppSelector(PeopleState)
const dispatch = useAppDispatch()

const initialState = {
    open: false,
    street: '',
    street2: '',
    region: '',
    postalCode: '',
    city: '',
    addressType: '',
    addressUse: '',

}

const [dialog, setDialog] = useState(initialState)
let { open,  street, region, postalCode,  city, addressType, street2, addressUse } = dialog

useEffect(() => {
    
},[region])

    const handleClickOpen: MouseEventHandler<HTMLButtonElement> = () => { setDialog({...dialog, open: true}) }
    const handleClose: MouseEventHandler<HTMLButtonElement> = () => {
        dispatch(clearError())
        setDialog(initialState)
    }

    const handleSubmit = () => {
        const addresses = [{use:addressUse, type:addressType, line1:street, line2:street2, city, state: region,postalCode}]

        if(!validator.isPostalCode(postalCode,'US')){
            dispatch(setErrorAsync('Postal Code is invalid!'))
            return
        }
        if(addressUse === ''){
            dispatch(setErrorAsync('Address Use is required!'))
            return
        }
        if(addressType === ''){
            dispatch(setErrorAsync('Address Type is required!'))
            return
        }
        if(!person){
            dispatch(setErrorAsync('Missing Person to associate address with!'))
            return
        }
        dispatch(clearError())
        dispatch(addNewAddressAsync(person,addresses, SubmitType.Submit))
        setDialog(initialState)
    }

    const handleSubmitAndContinue = () => {
        const addresses = [{use:addressUse, type:addressType, line1:street, line2:street2, city, state: region,postalCode}]

        if(!validator.isPostalCode(postalCode,'US')){
            dispatch(setErrorAsync('Postal Code is invalid!'))
            return
        }
        if(addressUse === ''){
            dispatch(setErrorAsync('Address Use is required!'))
            return
        }
        if(addressType === ''){
            dispatch(setErrorAsync('Address Type is required!'))
            return
        }
        if(!person){
            dispatch(setErrorAsync('Missing Person to associate address with!'))
            return
        }
        dispatch(clearError())
        dispatch(addNewAddressAsync(person,addresses, SubmitType.Next))
        setDialog({...dialog, street: '', street2: '', region: '', postalCode: '', city: '',})
    }
    
    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => setDialog({...dialog, [e.target.name]: e.target.value})

    const stateOptions = useEnhancedStates()

const selectUses = [{key:'billing',label:'Billing', value:'billing'},{key:'home',label:'Home', value:'home'},{key:'old',label:'Old', value:'old'},{key:'temp',label:'Temp', value:'temp'},{key: 'work',label:'Work', value:'work'}]
const selectTypes = [{key:'physical',label:'Physical', value:'physical'},{key: 'postal',label:'Postal', value:'postal'},{key:'both',label:'Both', value:'both'}]

if (!loading) return (
    <Fragment>
        <IconButton color='inherit' onClick={handleClickOpen}>
        <Tooltip title='Add Address'>
            <AddHomeWorkSharpIcon fontSize='medium' style={{color: '#000032'}}/>
        </Tooltip>
        </IconButton>                   
 
    <Dialog open={open} id="addAddressModal" onClose={handleClose} aria-labelledby={'form-dialog-title'} fullWidth={true} maxWidth={'md'} PaperComponent={PaperComponent}>
    <DialogTitle id='form-dialog-title'>Add Address</DialogTitle>
    <DialogContent>

        <DialogContentText>
            {
                error && <ShowAlert severity={'error'}>{error}</ShowAlert>
            }
        </DialogContentText>

        <Separator />

        <Grid container spacing={2}>
            <Grid item xs={12}>
            <FormControl variant="outlined" style={{minWidth: 200}} required>
                <InputLabel htmlFor="outlined-age-native-simple">Address Use</InputLabel>
                <Select
                    native
                    required
                    value={addressUse}
                    onChange={onChange}//{(val) => handleChange(val)}
                    label="Address Use"
                    inputProps={{
                        name: 'addressUse',
                        id: 'addressUse',
                    }}
                    >
                    <option aria-label="None" value="" />
                    {selectUses.map(item => {
                        return (<option key={item.key} value={item.value}>{item.label}</option>);
                        })}
                </Select>
            </FormControl>     
            </Grid>
            <Grid item xs={12}>
            <FormControl variant="outlined" style={{minWidth: 200}} required>
                <InputLabel htmlFor="outlined-age-native-simple">Address Type</InputLabel>
                <Select
                    native
                    required
                    value={addressType}
                    onChange={onChange}//{(val) => handleChange(val)}
                    label="Address Type"
                    inputProps={{
                        name: 'addressType',
                        id: 'addressType',
                    }}
                    >
                    <option aria-label="None" value="" />
                    {selectTypes.map(item => {
                        return (<option key={item.key} value={item.value}>{item.label}</option>);
                        })}
                </Select>
            </FormControl>     
            </Grid>
            <Grid item xs={12}>
                <TextField
                    margin='dense'
                    type='text'
                    id='street'
                    name='street'
                    label='Street Address'
                    value={street}
                    onChange={onChange}
                    variant='outlined'
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    margin='dense'
                    type='text'
                    id='street2'
                    name='street2'
                    label='Address Line 2'
                    value={street2}
                    onChange={onChange}
                    variant='outlined'
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    margin='dense'
                    type='text'
                    id='city'
                    name='city'
                    label='City'
                    value={city}
                    onChange={onChange}
                    variant='outlined'
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
            <FormControl variant="outlined" >
                <InputLabel htmlFor="outlined-age-native-simple">State</InputLabel>
                <Select
                    native
                    value={region}
                    onChange={onChange}//{(val) => handleChange(val)}
                    label="State"
                    inputProps={{
                        name: 'region',
                        id: 'region',
                    }}
                    >
                    <option aria-label="None" value="" />
                    {stateOptions.map(item => {
                        return (<option key={item.key} value={item.value}>{item.label}</option>);
                        })}
                </Select>
            </FormControl>
                    
            </Grid>
            <Grid item xs={12}>
                <TextField
                    margin='dense'
                    type='text'
                    id='postalCode'
                    name='postalCode'
                    label='Postal Code'
                    value={postalCode}
                    onChange={onChange}
                    variant='outlined'
                    fullWidth
                    required
                />
            </Grid>

        </Grid>

    </DialogContent>
    <DialogActions>
        <Button onClick={handleClose} color={'primary'}>
            Cancel
        </Button>
        <Button onClick={handleSubmitAndContinue} color={'primary'}>
            Save & Continue
        </Button>
        <Button onClick={handleSubmit} color={'primary'}>
            Save & Close
        </Button>
    </DialogActions>
</Dialog>
</Fragment>
)
else 
        return (<div></div>)
        }
export default AddAddress