import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from "../hooks"
import { useParams } from 'react-router-dom'

import { Outlet } from 'react-router-dom'
// import classNames from 'classnames'
// import clsx from 'clsx'

import { mainNavigationItems, secondaryListItems } from './navigationItems'

//context
import { AuthState, setAuthToken, setUserAsync } from '../features/auth/authSlice'

import { useAuth0 } from '@auth0/auth0-react'

// Material UI
import CssBaseline from '@mui/material/CssBaseline'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar, { AppBarProps } from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Container from '@mui/material/Container'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import LogoutIcon from '@mui/icons-material/Logout'
import styled from "@emotion/styled";
import Unauthorized from './Unauthorized'
import Spinner from './Spinner'
import { User } from '../models/user'

const drawerWidth = 240

const Main = styled('main')(({theme}) => ({
    paddingTop: '65px',
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
}))


const MainContent = () => {
    return (<Main>
        <div className={'classes.appBarSpacer'} />
        <Container className={'classes.container'} style={{maxWidth: '100%', height: '100%'}}>
            <Outlet />
        </Container>
    </Main>)
}

interface CustomAppBarProps extends AppBarProps {
    open: boolean;
  }
  

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<CustomAppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));



const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);



const Template = () => {
    const dispatch = useAppDispatch()
    const auth0 = useAuth0()
    const { isAuthenticated, user, logout } = auth0
    const { id } = useParams()

    const { isTokenSet, loading, user:authenticatedUser, userLoaded } = useAppSelector(AuthState)

    const pathname = window.location.pathname
    if (pathname === '/logout') logout()

    // create state for drawer
    const [open, setOpen] = React.useState(false)
    const handleDrawerOpen = () => {
        setOpen(true)
    }
    const handleDrawerClose = () => {
        setOpen(false)
    }

    // create state for user menu
    const [openProfile, setOpenProfile] = React.useState(null)
    // const handleClickProfile = event => {
    //     console.log(event.currentTarget)
    //     if (openProfile && openProfile.contains(event.target)) {
    //         setOpenProfile(null)
    //     } else {
    //         setOpenProfile(event.currentTarget)
    //     }
    // }

    // const handleCloseProfile = () => {
    //     setOpenProfile(null)
    // }

    // const inboxCount = 0

    useEffect(() => {
        if (!isTokenSet) {
            const {isAuthenticated, getAccessTokenSilently} = auth0
            dispatch(setAuthToken(isAuthenticated, getAccessTokenSilently))
        }

        if (isTokenSet && !authenticatedUser) dispatch(setUserAsync(user?.email ?? ''))
    }, [isTokenSet, auth0, authenticatedUser, dispatch, user?.email])

    if (userLoaded && !authenticatedUser ) {
        return (<Unauthorized />)
    }

    if (userLoaded && authenticatedUser && !authenticatedUser.isActive) return <Unauthorized />

    if (!userLoaded) return <Spinner />

    return (
        <div style={{display: 'flex'}}>
            <CssBaseline />
            <AppBar position='absolute' color='primary' open={open} className={'test'}>
                <Toolbar style={{paddingRight: 24 }}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        style={{color: 'white'}}
                        sx={{
                            flexGrow: 1,
                            fontSize: '1.8rem',
                            fontFamily: 'Constantia',
                        }}
                        noWrap>
                        Unit 387
                    </Typography>
                    <Typography component="p" style={{color: 'white'}} noWrap>
                        {isAuthenticated && user?.name}
                    </Typography>
                    <IconButton onClick={() => logout()} style={{color: "white"}}>
                        <LogoutIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <Drawer variant='permanent' open={open}>
                <div
                    style={{
                        height: '65px',
                        paddingTop: '15px',
                        paddingLeft: '10px'
                    }}
                >
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <List>{mainNavigationItems(open, (authenticatedUser as User).organization.defaultDatasourceId)}</List>
                <Divider />
                {id && <List>{secondaryListItems(id,open) }</List>}
            </Drawer>

            {/* Main Content */}
            { !loading && <MainContent /> }
        </div>
    )

}

export default Template