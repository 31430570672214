import { useEffect, Fragment } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from "../../hooks"

// components
import ChartDemographics from "./ChartDemographics"
import ChartHeader from './ChartHeader'
import ClientFilesList from './ClientFilesList'
import RequestHistory from './RequestHistory'
import ReciprocalDataHistory from './ReciprocalDataHistory'
import Spinner from '../../layouts/Spinner'

// context
import { PeopleState, getPersonAsync, setError } from "./peopleSlice"
import {AuthState} from "../auth/authSlice"

// mui
import { Grid } from "@mui/material";
import ProgressNoteAlert from '../reciprocalData/ProgressNoteAlert'
import NoFacilityPopUp from '../patientQuery/NoFacilityPopUp'
import ErrorAlert from './ErrorAlert'
import { DataSource } from '../../models/dataSource'

const PersonDetail = () => {
    const dispatch = useAppDispatch()
    const { id } = useParams()
    const { user } = useAppSelector(AuthState)

    const { person, loading, success, noFacility, error } = useAppSelector(PeopleState)

    useEffect(() => {
        id ? dispatch(getPersonAsync(id)) : dispatch(setError('Route Path Parameter For Person ID Missing'))
    }, [dispatch, id])
    

    if (loading) return (<Spinner />)

    return (!!person) ? (
        <Fragment>
            {success &&  <ProgressNoteAlert message={'Progress Note Added'}/>  }
            {noFacility && <NoFacilityPopUp message={noFacility}/>}
            {error && <ErrorAlert message={error}/>}

            <div style={{marginLeft: 10, marginTop: "30px"}}>
                <ChartHeader person={person} />
                <Grid container spacing={2}>
                    <Grid item xs={12} xl={6}>
                        <ChartDemographics person={person} />
                    </Grid>
                    <Grid item xs={12} xl={6}>
                        <RequestHistory person={person} />
                        {user?.organization.defaultDatasourceId === DataSource.HG && <ReciprocalDataHistory person={person} />}
                    </Grid>
                </Grid>

                <ClientFilesList person={person} />
            </div>

        </Fragment>
    ) : (<Fragment><div></div></Fragment>)
}

export default PersonDetail

