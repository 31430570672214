import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from "react-draggable";

function PaperComponent(props: PaperProps) {
    return (
      <Draggable
        handle="#form-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
}

export default PaperComponent