import { ChangeEvent, Fragment, MouseEventHandler, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Grid,
    TextField,
    Tooltip,
    Button,
    IconButton,
    MenuItem,
    SelectChangeEvent
} from "@mui/material"

import Alert from '@mui/material/Alert';
import EditSharpIcon from '@mui/icons-material/EditSharp';

// context
import { updatePersonAsync, clearError, PeopleState } from "./peopleSlice"

import PaperComponent from '../../widgets/PaperComponent';
import { Person } from '../../models/person';

const EditPerson = (props: {dataFromParent: Person}) => {

    const { error } = useAppSelector(PeopleState)
    const dispatch = useAppDispatch()

    const initialState = {
        open: false,
        firstName: props.dataFromParent.firstName,
        middleName: props.dataFromParent.middleName,
        lastName: props.dataFromParent.lastName,
        suffix: props.dataFromParent.suffix,
        gender: props.dataFromParent.gender,
        birthDate: props.dataFromParent.birthDate,
        batchId: props.dataFromParent.BatchIds[0]?.batchId
    }

    const [dialog, setDialog] = useState(initialState)
    const { open, firstName, lastName, birthDate, suffix, gender, middleName, batchId } = dialog

    const handleClickOpen: MouseEventHandler<HTMLButtonElement> = () => { 
        dispatch(clearError())
        setDialog({...dialog, open: true}) }
    const handleClose: MouseEventHandler<HTMLButtonElement> = () => {
        dispatch(clearError())
        setDialog(initialState)
    }
    const handleSubmit: MouseEventHandler<HTMLButtonElement> = () => {
        dispatch(clearError())
        dispatch(updatePersonAsync({id:props.dataFromParent.id,firstName,lastName, birthDate,suffix, middleName, gender, batchId}))
        setDialog(initialState)
    }

    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => setDialog({...dialog, [e.target.name]: e.target.value})

    const selectGenders = [{key:'male',label:'Male', value:'male'},{key:'female',label:'Female', value:'female'},{key:'other',label:'Other', value:'other'},{key:'unknown',label:'Unknown', value:'unknown'}]

    return (
        <Fragment>
            <IconButton color='inherit' onClick={handleClickOpen} style={{marginLeft: "30px"}}>
                <Tooltip title='Edit Person'>
                    <EditSharpIcon fontSize='small' style={{color: '#939393'}}/>
                </Tooltip>
            </IconButton>                   
    
            <Dialog open={open} id="editPersonModal" onClose={handleClose} aria-labelledby={'form-dialog-title'} fullWidth={true} maxWidth={'md'} PaperComponent={PaperComponent}>
                <DialogTitle id='form-dialog-title'>Update Person Info</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        { error && <Alert severity={'error'}>{error}</Alert> }
                    </DialogContentText>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                margin='dense'
                                type='text'
                                id='firstName'
                                name='firstName'
                                label='First Name'
                                value={firstName}
                                onChange={onChange}
                                variant='outlined'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin='dense'
                                type='text'
                                id='middleName'
                                name='middleName'
                                label='Middle Name'
                                value={middleName}
                                onChange={onChange}
                                variant='outlined'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin='dense'
                                type='text'
                                id='lastName'
                                name='lastName'
                                label='Last Name'
                                value={lastName}
                                onChange={onChange}
                                variant='outlined'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin='dense'
                                type='text'
                                id='suffix'
                                name='suffix'
                                label='Suffix'
                                value={suffix}
                                onChange={onChange}
                                variant='outlined'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin='dense'
                                type='text'
                                id='birthDate'
                                name='birthDate'
                                label='D.O.B'
                                value={birthDate}
                                onChange={onChange}
                                variant='outlined'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin='dense'
                                select
                                id='gender'
                                name='gender'
                                label='Gender'
                                value={gender}
                                onChange={onChange}
                                variant='outlined'
                            >
                                <MenuItem value="" style={{ height: "36px" }}>
                                </MenuItem>
                                {selectGenders.map(item => {
                                    return (<MenuItem key={item.key} value={item.value}>{item.label}</MenuItem>);
                                })}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin='dense'
                                type='text'
                                id='batchId'
                                name='batchId'
                                label='Batch Id'
                                value={batchId}
                                onChange={onChange}
                                variant='outlined'
                                fullWidth
                            />
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color={'primary'}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color={'primary'}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}
export default EditPerson