import { Dispatch, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from "../hooks"
import SearchIcon from "@mui/icons-material/Search";
import {TextField, Tooltip, Link, Button, InputAdornment} from "@mui/material";


import {PeopleState, getPeopleBasicAsync} from '../features/people/peopleSlice'

const SearchBar = ({setSearchQuery}: {setSearchQuery: Dispatch<React.SetStateAction<string>>}) => (
    <form>
      <TextField
        id="search-bar"
        className="text"
        onInput={(e) => {
          setSearchQuery((e.target as HTMLInputElement).value);
        }}
        label="Search for a patient"
        variant="outlined"
        placeholder=""
        size="small"
        fullWidth
        InputProps={{
            endAdornment: (
                <InputAdornment position="start">
                    <SearchIcon />
                </InputAdornment>
            )
        }}
      />
    </form>
  );

  const filterData = (query: string, data: {id: string, name: string}[]) => {
    if (!query) {
      return data;
    } else {
      const returnData = data.filter((d) => d.name.toLowerCase().includes(query.toLowerCase()));
    return returnData
    }
  };

  const getDetailButton = (id: string, name: string) => {
    return <Link href={"/people/"+ id}>
                <Tooltip title='View Details'>
                    <Button variant='text'>{name}</Button>
                </Tooltip>
            </Link>
  }
 


export default function HomeSearch() { 

    const dispatch = useAppDispatch()
    //Destructure of People State elements
    const { peopleBasic:people } = useAppSelector(PeopleState)

    useEffect(() => {
        dispatch(getPeopleBasicAsync())
    }, [dispatch])

    const data = people.map(a => { return {id:a.id, name:a.searchName}})

    const [searchQuery, setSearchQuery] = useState("");
    const dataFiltered = filterData(searchQuery, data);
    
    return (
      <div
        style={{
          display: "block",
          alignSelf: "center",
          justifyContent: "center",
            marginBottom: 10,
            width: "100%"
        }}
      >
        <SearchBar setSearchQuery={setSearchQuery} />
        <div style={{ position: 'absolute', backgroundColor: 'white', border: '1px solid lightgray', zIndex: '999' }}>
          {searchQuery && dataFiltered.map((d) => (
            <div
              className="text"
              style={{
                padding: 1,
                justifyContent: "normal",
                fontSize: 20,
                color: "#000032",
                margin: 1,
                width: "250px",
                borderColor: "green",
                borderWidth: "10px"
              }}
              key={d.id}
            >
              {getDetailButton(d.id, d.name)} 
            </div>
          ))}
        </div>
      </div>
    );
}